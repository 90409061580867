/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import TextEditor from '../../Components/TextEditor/TextEditor'

export default function PrivacyPolicy() {

  const serverURL = process.env.REACT_APP_SERVER_URL;
  const authSlice = useSelector((state) => state.auth);

  const defaultValues = {
    privacyPolicy: ''
  }

  const [data, setData] = useState(defaultValues);

  const textChangeHandler = (value) => {
    setData((prevState) => {
      return {
        ...prevState,
        [value.name]: value.value
      }
    })
  }

  const getData = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${serverURL}/api/privacy-policy`,
      })
      if (res && res.status === 200) {
        setData(res.data.data);
        toast.success('Data fetched successfully!')
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error("No data found");
      } else {
        toast.error("Couldn't get the data")
      }
    }
  }

  const submitHandler = async () => {

    try {
      const res = await axios({
        method: 'POST',
        url: `${serverURL}/api/privacy-policy`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authSlice.token}`
        },
        data
      })
      if (res && res.status === 200) {
        toast.success('Data updated successfully!');
      } else {
        throw new Error()
      }

    } catch (error) {
      toast.error("Couldn't update the data");
    }
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <Fragment>
      <div className="data-box mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Privacy Policy</h2>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">

              <div className="form-group">
                <TextEditor name="privacyPolicy" value={data.privacyPolicy} onChange={textChangeHandler} />
              </div>
              <div className="form-group">
                <button type='button' onClick={submitHandler} className="btn-orange border-none cursor-pointer">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
