/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

export default function JobApplication() {

    const backendURL = process.env.REACT_APP_SERVER_URL;

    const [subscribers, setSubscribers] = useState([]);
    const authSlice = useSelector((state) => state.auth);

    const get = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${backendURL}/api/subscriber`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                setSubscribers(res.data.data);
                toast.success("Data fetched successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't fetch the data!")
        }

    }

    const deleteHandler = async (id) => {
        if (!id) {
            return toast.error("Not ID")
        }

        try {

            const res = await axios({
                method: "DELETE",
                url: `${backendURL}/api/subscriber/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                const newData = subscribers.filter((item) => item._id !== id);
                setSubscribers(newData);
                toast.success("Data deleted successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't delete the data!")
        }

    }

    useEffect(() => {
        get();
    }, [])


    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Subscribers</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Subscriber Email</th>
                                        <th>Created at</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subscribers && subscribers.length !== 0 && subscribers.map((subscriber, idx) => {

                                            let date = new Date(subscriber.createdAt)

                                            date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${date.toLocaleTimeString()}`

                                            return (
                                                <tr key={subscriber._id} >
                                                    <td>{idx + 1}</td>
                                                    <td>{subscriber.email}</td>
                                                    <td>{date}</td>
                                                    <td className="text-center">
                                                        <button onClick={() => deleteHandler(subscriber._id)} className="btntag-danger">Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
