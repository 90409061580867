import React, { Fragment, useRef } from "react";
import "./DragAndDropFiles.css";

const DragAndDropFiles = (props) => {
  const inputRef = useRef(null);

  const onDragEnter = () => {
    inputRef.current.classList.add("drop-area-hover");
  };

  const onDragLeave = () => {
    inputRef.current.classList.remove("drop-area-hover");
  };

  const onDrop = () => {
    inputRef.current.classList.remove("drop-area-hover");
  };

  const onFileDrop = (e) => {
    const newFiles = e.target.files;
    if (newFiles) {
      props.onFilesUpload(newFiles)
    }
  };

  return (
    <Fragment>
      <div className="wrapper">
        <div
          className="drop-area"
          ref={inputRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <ion-icon name="cloud-upload-outline"></ion-icon>
          <span style={{ fontSize: '1rem' }} >Drop your files here</span>
          <input accept="image/*" className="drag-drop-input" id="input" type="file" onChange={onFileDrop} multiple={true} />
        </div>
      </div>
    </Fragment>
  );
};

export default DragAndDropFiles;
