/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-hot-toast';

export default function Contact() {

    const serverURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth);

    const defaultValues = {
        address: '',
        phone: '',
        email: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        youtube: '',
        whatsApp: '',
        googleMap: '',
    }

    const [data, setData] = useState(defaultValues);

    const textChangeHandler = (event) => {
        setData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const getData = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${serverURL}/api/contact`,
            })

            if (res && res.status === 200) {
                setData(res.data.data);
                toast.success('Data fetched successfully!')
            } else {
                throw new Error();
            }
        } catch (error) {
            if (error.response.status === 404) {
                toast.error("No data found");
            } else {
                toast.error("Couldn't get the data")
            }
        }
    }

    const submitHandler = async () => {

        try {
            const res = await axios({
                method: 'POST',
                url: `${serverURL}/api/contact`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`,
                    "Content-Type": 'application/json'
                },
                data
            })
            if (res && res.status === 200) {
                toast.success('Data updated successfully!');
            } else {
                throw new Error()
            }

        } catch (error) {
            toast.error("Couldn't update the data");
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Contact Details</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12" >
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>Address</label>
                                        <input onChange={textChangeHandler} name='address' value={data.address} type="text" className="form-control shadow-none" />
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Phone</label>
                                        <input onChange={textChangeHandler} name='phone' value={data.phone} type="tel" className="form-control shadow-none" />
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Email</label>
                                        <input onChange={textChangeHandler} name='email' value={data.email} type="email" className="form-control shadow-none" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>Facebook Profile URL - <i className="fa fa-facebook-square"></i></label>
                                        <input onChange={textChangeHandler} name='facebook' value={data.facebook} type="text" className="form-control shadow-none" />
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Instagram Profile URL - <i className="fa fa-instagram"></i></label>
                                        <input onChange={textChangeHandler} name='instagram' value={data.instagram} type="text" className="form-control shadow-none" />
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Twitter Profile URL - <i className="fa fa-twitter-square"></i></label>
                                        <input onChange={textChangeHandler} name='twitter' value={data.twitter} type="text" className="form-control shadow-none" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>Linkedin Profile URL - <i className="fa fa-linkedin-square"></i></label>
                                        <input onChange={textChangeHandler} name='linkedin' value={data.linkedin} type="text" className="form-control shadow-none" />
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Youtube Profile URL - <i className="fa fa-youtube-square"></i></label>
                                        <input onChange={textChangeHandler} name='youtube' value={data.youtube} type="text" className="form-control shadow-none" />
                                    </div>
                                    <div className="col-lg-4">
                                        <label>WhatsApp Profile URL - <i className="fa fa-whatsapp"></i></label>
                                        <input onChange={textChangeHandler} name='whatsApp' value={data.whatsApp} type="text" className="form-control shadow-none" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Google Map IFRAME URL</label>
                                <input onChange={textChangeHandler} name='googleMap' value={data.googleMap} type="text" className="form-control shadow-none" />
                            </div>
                            <div className="form-group">
                                {data.googleMap && data.googleMap.length !== 0 && <iframe title='Map' src={data.googleMap} ></iframe>}
                            </div>
                            <input type='button' onClick={submitHandler} className="btn-orange border-none cursor-pointer" value='Submit' />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
