/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ImagePicker = (props) => {

    const property = useSelector((state) => state.property)

    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const navigate = useNavigate();

    const fileChangeHandler = (event) => {
        if (event.target.files && event.target.files.length !== 0) {
            setFile(event.target.files[0]);
        }
    }

    const removeHandler = async () => {
        setFile(null);
        setPreview(null);
        document.getElementById(props.name).value = ''
        if (props.onDelete) {
            props.onDelete();
        }
    }

    useEffect(() => {
        props.onChange(file);
        if (file !== null) {
            const reader = new FileReader();

            reader.onload = () => {
                setPreview(reader.result);
            }

            reader.readAsDataURL(file)
        }
    }, [file])

    const getExisttingImages = async () => {
        if (property._id && property._id.length !== 0) {
            try {
                const img = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_SERVER_URL}/api/${props.name}/${property._id}`
                })
                if (img && img.status === 200 && img.data.data && img.data.data.path && img.data.data.path.length !== 0) {
                    setPreview(`${process.env.REACT_APP_SERVER_URL}/${img.data.data.path}`);
                }
            } catch (error) {
                if (!error.response || !error.response.status || !error.response.status === 404) {
                    toast.error('Something went wrong')
                    navigate('/property-listing')
                }
            }
        }
    }

    useEffect(() => {
        if (props.for && props.for === 'property') {
            getExisttingImages();
        }
    }, [property])

    return (
        <div className="form-group">
            <label>{props.label}</label>
            <div>
                <input accept="image/png, image/gif, image/jpeg, image/jpg" id={props.name} onChange={fileChangeHandler} type="file" className="form-control shadow-none" />
                <div className="image-container mt-10">
                    <img src={preview !== null ? preview : "images/image-preview.jpg"} className="img-fluid" alt='' />
                </div>
                {preview && <button onClick={removeHandler} className='btntag-danger border-none mt-10' >Remove</button>}
            </div>
        </div>
    )

}

export default ImagePicker