import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react';

const TextEditor = (props) => {
    return (
        <CKEditor
            editor={Editor}
            data={props.value}
            onReady={editor => {
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                props.onChange({
                    name: props.name,
                    value: data
                });
            }}
            onBlur={(event, editor) => {
            }}
            onFocus={(event, editor) => {
            }}
        />
    );
}

export default TextEditor;
