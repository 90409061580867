/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import FilePicker from '../../../Components/FilePicker/FilePicker';

export default function PropertyVideo() {

    const serverURL = process.env.REACT_APP_SERVER_URL;

    const defaultAddState = {
        property: '',
        video: null
    }

    const [properties, setProperties] = useState([]);
    const [addState, setAddState] = useState(defaultAddState)
    const [videoToUpload, setVideoToUpload] = useState(null)
    const authSlice = useSelector((state) => state.auth);

    const [currProperty, setCurrProperty] = useState(null);

    const [propertyVideo, setPropertyVideo] = useState('');


    const getProperties = async () => {

        try {
            const res = await axios({
                method: 'GET',
                url: `${serverURL}/api/property`,
            })

            try {
                const { data } = res.data;
                setProperties(data);
            } catch (error) {
                throw new Error();
            }
        } catch (error) {
            toast.error("Couldn't fetch the data")
        }
    }

    const addPropertyChangeHandler = (event) => {
        setAddState((prevState) => {
            return {
                ...prevState,
                property: event.target.value
            }
        })
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        if (videoToUpload) {
            const data = new FormData();

            data.append('property', addState.property);
            data.append('video', videoToUpload);

            await axios({
                method: 'POST',
                url: `${serverURL}/api/property-video/`,
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: data
            })
        }

        getVideo();
        setAddState(defaultAddState);
    }

    const videoChangeHandler = (file) => {
        setVideoToUpload(file);
    }

    const removeHandler = async (id) => {

        try {
            const res = await axios({
                method: 'DELETE',
                url: `${serverURL}/api/property-video/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                },
            })
            if (res && res.status === 200) {
                toast.success("Data deleted successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Coudln't delete data!")
        }

        getVideo();
    }

    const getVideo = async () => {
        try {
            const resPropertyVideo = await axios({
                method: 'GET',
                url: `${serverURL}/api/property-video/${currProperty}`,
            });
            if (resPropertyVideo && resPropertyVideo.status === 200) {
                setPropertyVideo(resPropertyVideo.data.data.path)
            }
        } catch (error) {
            setPropertyVideo(null);
        }
    }

    useEffect(() => {
        getProperties();
    }, [])

    useEffect(() => {
        setPropertyVideo(null);
        if (currProperty === '') {
            setPropertyVideo(null);
        } else {
            getVideo();
        }
    }, [currProperty])


    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Property Video</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <label>Select property to view data</label>
                                <select onChange={(event) => setCurrProperty(event.target.value)} value={currProperty} className="form-control shadow-none mb-30">
                                    <option value='' >Property ?</option>
                                    {
                                        properties.map((property) => {
                                            return (
                                                <option key={property._id} value={property._id}>{property.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    currProperty !== '' && propertyVideo && <div>
                                        {
                                            currProperty !== '' && propertyVideo && <div  >
                                                <video width="100%" controls>
                                                    <source src={`${serverURL}/${propertyVideo}`} poster={`${serverURL}/${propertyVideo}`} type="video/mp4" />
                                                    Your browser does not support HTML video.
                                                </video>
                                                <button type='button' className="btntag-danger border-none cursor-pointer" onClick={() => removeHandler(currProperty)}>Remove Video</button>
                                            </div>
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                        <form className="col-lg-4 bg-light padding-40" onSubmit={onSubmitHandler}>
                            <h3>Add Property Video</h3>
                            <hr />
                            <div className="form-group">
                                <label>Property Name</label>
                                <select className="form-control shadow-none" value={addState.property} onChange={addPropertyChangeHandler} >
                                    <option value="">Property ?</option>
                                    {
                                        properties.map((property) => {
                                            return (
                                                <option key={property._id} value={property._id}>{property.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                {addState.property && <FilePicker name='brochure' label='property Video' accept='.mp4' onChange={videoChangeHandler} />
                                }
                            </div>
                            <div className="form-group">
                                <button type='submit' className="btn-orange-sm border-none cursor-pointer">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
