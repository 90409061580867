import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../store/authSlice';
import './Login.css'

const Login = (props) => {


    const backendURL = process.env.REACT_APP_SERVER_URL;

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);

    const emailChangeHandler = (event) => {
        setEmail(event.target.value)
        setShowError(false)
    }

    const passwordChangeHandler = (event) => {
        setPassword(event.target.value)
        setShowError(false)
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        if (!email || email.length === 0 || !password || password.length === 0) {
            setShowError(true);
        }

        try {
            const res = await axios({
                method: "POST",
                url: `${backendURL}/api/auth/login`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    email, password
                }
            })

            if (res && res.status === 200) {
                dispatch(authActions.login({ token: res.data.token, adminType: res.data.adminType }));
                navigate('/')
            } else {
                throw new Error();
            }
        } catch (error) {
            return setShowError(true)
        }

        setEmail('');
        setPassword('');
    }

    return (
        <div class="login-form-box" id="login-form-box">
            <div class="login-container">
                <div class="login-content">
                    <div class="login-banner">
                        <img src={`${process.env.REACT_APP_FRONTEND_URL}/images/logo.png`} class="logo" alt="" />
                        <br />
                        <h1>Welcome Back!</h1>
                        <div class="orange-line"></div>
                        <h3>Login to your Account.</h3>

                    </div>
                    <form onSubmit={submitHandler} class="login-form">
                        <img src="https://i.pinimg.com/236x/4d/a8/bb/4da8bb993057c69a85b9b6f2775c9df2.jpg" class="user" alt="profile" />
                        {showError && <p style={{ color: '#ed1a23' }} >Couldn't login with these credentials</p>}
                        <input name='email' value={email} onChange={emailChangeHandler} type="email" className='login-id' autocomplete="off" placeholder="Email" />
                        <input name='password' value={password} onChange={passwordChangeHandler} type="password" className='login-id' placeholder="Password" />
                        <button type="submit">LOGIN</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;