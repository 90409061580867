/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast';

import ImagePicker from '../../../Components/ImagePicker/ImagePicker'
import { useSelector } from 'react-redux';

export default function FloorPlan() {

  const serverURL = process.env.REACT_APP_SERVER_URL;

  const defaultState = {
    property: '',
    value: '',
    image: null,
  }

  const [floorPlans, setFloorPlans] = useState([]);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [currProperty, setCurrProperty] = useState('');
  const [currItem, setCurrItem] = useState(defaultState);
  const authSlice = useSelector((state) => state.auth);

  const getFloorPlans = async () => {

    try {
      const res = await axios({
        method: 'GET',
        url: `${serverURL}/api/floor-plan`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })
      if (res && res.status === 200) {
        const { data } = res.data;
        setFloorPlans(data);
      }
    } catch (error) {
      if (error) {
        toast.error("Couldn't fetch the data")
      }
    }
  }

  const getProperties = async () => {

    try {
      const res = await axios({
        method: 'GET',
        url: `${serverURL}/api/property`,
      })

      if (res && res.status === 200) {
        const { data } = res.data;
        setProperties(data);
      }
    } catch (error) {
      toast.error("Couldn't fetch the data")
    }
  }

  const createData = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      formData.append('property', currItem.property.split('-')[0])
      formData.append('value', currItem.value.split('-')[0])
      formData.append('image', currItem.image)

      const res = await axios({
        method: 'POST',
        url: `${serverURL}/api/floor-plan`,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${authSlice.token}`
        },
        data: formData
      })

      if (res && res.status === 200) {
        const newData = [...floorPlans];
        newData.push({
          _id: res.data.data._id,
          value: {
            name: currItem.value.split('-')[1]
          },
          property: {
            title: currItem.property.split('-')[1]
          },
          image: res.data.data.image,
        })
        setFloorPlans(newData);
      }
      setCurrItem(defaultState);
      setCurrProperty("")
      toast.success("Successfully created the data")
    } catch (error) {
      toast.error("Couldn't create the data")
    }
  }

  const deleteData = async (id) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `${serverURL}/api/floor-plan/${id}`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        let newData = [...floorPlans];
        newData = newData.filter((item) => item._id !== id);
        setFloorPlans(newData);
      }
    } catch (error) {
      toast.error("Couldn't delete the data")
    }
  }

  const getUnits = async (propertyID) => {
    try {
      let res = await axios({
        method: 'GET',
        url: `${serverURL}/api/units`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        let units = res.data.data;
        units = units.filter((unit) => unit.property._id === propertyID)
        setUnits(units);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error("Couldn't fetch the data")
    }
  }

  const propertyChangeHandler = (event) => {
    setCurrProperty(event.target.value.split('-')[0]);
    setCurrItem((prevState) => {
      return {
        ...prevState,
        property: event.target.value
      }
    })
  }

  const unitChangeHandler = (event) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        value: event.target.value
      }
    })
  }

  const imageChangeHandler = (file) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        image: file
      }
    })
  }


  useEffect(() => {
    getFloorPlans();
    getProperties();
  }, [])

  useEffect(() => {
    getUnits(currProperty);
  }, [currProperty])


  return (
    <Fragment>
      <div className="data-box mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Floor Plans</h2>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">

              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Property Name</th>
                    <th>BHK Type</th>
                    <th>Floor Plan Image</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    floorPlans.map((floorPlan, idx) => {
                      return (
                        <tr key={floorPlan._id} >
                          <td>{idx + 1}</td>
                          <td>{floorPlan.property.name}</td>
                          <td>{floorPlan.value.name}</td>
                          <td>
                            <img width='25%' src={`${serverURL}/${floorPlan.image}`} alt="floor plan" />
                          </td>
                          <td>
                            <div className='flex' >
                              <button className="btntag-danger" onClick={() => deleteData(floorPlan._id)}  >DELETE</button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <form className="col-lg-4 bg-light padding-40" onSubmit={createData}>
              <h3>Add Floor Plans</h3>
              <hr />
              <div className="form-group">
                <label>Property Name</label>
                <select value={currItem.property} className="form-control shadow-none" onChange={propertyChangeHandler}>
                  <option value='' >Property ?</option>
                  {
                    properties.map((property) => {
                      return (
                        <option key={property._id} value={`${property._id}-${property.title}`}>{property.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="form-group">
                <label>Select BHK</label>
                <select className="form-control shadow-none" onChange={unitChangeHandler}>
                  <option>Select BHK</option>
                  {
                    units.map((unit) => {
                      return (
                        <option key={unit._id} value={`${unit._id}-${unit.name}`}>{unit.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="form-group">
                <label>Floor Plan Image</label>
                <div className="image-container mt-10">
                  {currItem.value && <ImagePicker onChange={imageChangeHandler} id='floor=plan-image' title='Floor Plan Image' />}
                </div>
              </div>
              <div className="form-group">
                <button type='submit' className="btn-orange-sm border-none cursor-pointer">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
