import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { propertyActions } from '../../../store/propertySlice';


export default function PropertyListing() {
    const [properties, setProperties] = useState([]);
    const dispatch = useDispatch();
    dispatch(propertyActions.setInitialState())
    const authSlice = useSelector((state) => state.auth);


    const getProperties = async () => {

        try {
            const res = await axios({
                url: `${process.env.REACT_APP_SERVER_URL}/api/property`,
                method: 'GET',
            });

            if (res && res.status === 200) {
                toast.success('Data fetched successfully!')
                setProperties(res.data.data)
            } else {
                toast.error("Couldn't fetch the data")
            }

        } catch (error) {
            toast.error("Something went wrong")
        }


    }

    const deleteProperty = async (id) => {

        try {
            const res = await axios({
                url: `${process.env.REACT_APP_SERVER_URL}/api/property/${id}`,
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                },

            })

            if (res && res.status === 200) {
                toast.success('Successfully deleted the data')
                const newProperties = properties.filter((item) => item._id !== id)
                setProperties(newProperties)
            }

        } catch (error) {
            toast.success("Couldn't delete the data")
        }

    }

    useEffect(() => {

        getProperties();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Properties</h2>
                                <Link to={"/add-property-listing"} className="btn-orange-sm">Add New Property</Link>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <table className="table table-bordered table-striped table-hover">
                                <tr>
                                    <th>SR No.</th>
                                    <th>Property Name</th>
                                    <th>Project Type</th>
                                    <th>Property Price (₹)</th>
                                    <th>Show On Homepage</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                {
                                    properties.map((property, idx) => {
                                        return (
                                            <tr key={property._id} >
                                                <td>{idx + 1}</td>
                                                <td>{property.name}</td>
                                                <td>{property.onGoingProject ? "Ongoing Project" : "Past Project"}</td>
                                                <td>{property.price}</td>
                                                <td>
                                                    {property.showOnHomePage === false ? <Fragment><i className="fa fa-circle text-danger"></i> Inactive</Fragment> : <Fragment><i className="fa fa-circle text-success"></i> Active</Fragment>}
                                                </td>
                                                <td>
                                                    {property.propertyStatus === false ? <Fragment><i className="fa fa-circle text-danger"></i> Inactive</Fragment> : <Fragment><i className="fa fa-circle text-success"></i> Active</Fragment>}
                                                </td>
                                                <td>
                                                    <Link to={`/edit-property-listing/${property._id}`} className="btntag-edit">Edit</Link>
                                                    <button className="btntag-danger border-none" onClick={() => deleteProperty(property._id)} >Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
