import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: '',
    title: '',
    address: '',
    hira: '',
    price: '',
    overviewTitle: '',
    overviewDescription: '',
    landArea: '',
    units: '',
    openSpace: '',
    noOfFlats: '',
    noOfTowers: '',
    noOfFloors: '',
    floorPlanTitle: '',
    amenitiesTitle: '',
    locationTitle: '',
    locationMap: '',
    placesNearBy: [],
    amenities: [],
    categories: [],
    galleryTitle: '',
    galleryDescription: '',
    metaTitle: '',
    metaDescription: '',
    metaKeyWords: '',
    propertyVideoTitle: '',
    showOnHomePage: false,
    propertyStatus: false,
    onGoingProject: false,
    floorPlanShow: false,
    amenitiesShow: false,
    locationShow: false,
    galleryShow: false,
    mainLocation: '',
    mainZone: '',
    subZone: ''
}

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        textValueChangeHandler(state, action) {
            const { payload } = action;
            state[payload.name] = payload.value
        },
        checkValueChangeHandler(state, action) {
            const { payload } = action;
            state[payload.name] = !state[payload.name]
        },
        placesNearByChangeHandler(state, action) {
            const { payload } = action;
            state.placesNearBy = payload
        },
        amenitiesChangeHandler(state, action) {
            const { payload } = action
            state.amenities = payload
        },
        categoriesChangeHandler(state, action) {
            const { payload } = action
            state.categories = payload
        },
        setValues(state, action) {
            const { payload } = action;
            for (let key in state) {
                state[key] = payload[key]
            }
            state._id = payload._id
        },
        setInitialState(state, action) {
            for (let key in state) {
                state[key] = initialState[key]
            }
        }
    }
})

export const propertyActions = propertySlice.actions;

export default propertySlice.reducer;