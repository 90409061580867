/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from 'react-hot-toast';

import TextEditor from '../../Components/TextEditor/TextEditor'
import { useDispatch, useSelector } from 'react-redux';
import { faqActions } from '../../store/faqSlice';


export default function Faq() {
    const serverURL = process.env.REACT_APP_SERVER_URL;

    const dispatch = useDispatch();
    const authSlice = useSelector((state) => state.auth);

    const defaultState = {
        question: '',
        answer: '',
    }

    const [faqs, setFaqs] = useState([]);
    const [data, setData] = useState(defaultState);
    const [editMode, setEditMode] = useState(false);

    const questionChangeHandler = (event) => {
        setData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const answerChangeHandler = (textData) => {
        setData((prevState) => {
            return {
                ...prevState,
                [textData.name]: textData.value
            }
        })
    }

    const getAll = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${serverURL}/api/faq`
            })

            if (res && res.status === 200) {
                setFaqs(res.data.data);
                toast.success("Data fetched successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't fetch the data")
        }
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        try {

            const res = await axios({
                method: "POST",
                url: `${serverURL}/api/faq`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data
            })

            if (res && res.status === 200) {
                const newData = [...faqs];
                newData.push({
                    question: res.data.data.question,
                    answer: res.data.data.answer,
                    _id: res.data.data._id
                })

                setFaqs(newData);
                setData(defaultState);
                toast.success('Data created successfully!')
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't create the data")
        }

    }

    const editHandler = async (faq, idx) => {
        setData({
            question: faq.question,
            answer: faq.answer,
            _id: faq._id,
            idx
        });
        setEditMode(true);
    }

    const editSaveHandler = async () => {
        try {
            const res = await axios({
                method: "PATCH",
                url: `${serverURL}/api/faq/${data._id}`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: {
                    ...data,
                    _id: undefined,
                    idx: undefined
                }
            })

            if (res && res.status === 200) {
                const newData = [...faqs];
                newData[data.idx] = {
                    question: res.data.data.question,
                    answer: res.data.data.answer,
                    _id: res.data.data._id
                };
                setFaqs(newData);
                setData(defaultState);
                setEditMode(false);
                toast.success('Data edited successfully!')
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't create the data")
        }
    }

    const deleteHandler = async (id) => {

        try {
            const res = await axios({
                method: 'DELETE',
                url: `${serverURL}/api/faq/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            });

            if (res && res.status === 200) {
                const newPosts = faqs.filter((item) => item._id !== id);
                setFaqs(newPosts);
                toast.success('Data deleted successfully!')
            } else {
                throw new Error();
            }
        } catch (error) {
            toast.error("Couldn't delete the data")
        }
    }

    const showModal = (question, answer) => {
        dispatch(faqActions.setValues({ question, answer }));
        dispatch(faqActions.openModal());
    }

    useEffect(() => {
        getAll();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>FAQ's</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">

                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Question</th>
                                        <th>Answers</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        faqs.map((faq, idx) => {
                                            return (
                                                <tr key={faq._id} >
                                                    <td>{idx + 1}</td>
                                                    <td>{faq.question}</td>
                                                    <td><button onClick={() => showModal(faq.question, faq.answer)} className="btntag-purp"><i className="fa fa-eye"></i> View</button></td>
                                                    <td className="text-center">
                                                        <button onClick={() => editHandler(faq, idx)} className="btntag-edit">Edit</button>
                                                        <button onClick={() => deleteHandler(faq._id)} className="btntag-danger">Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={submitHandler} className="col-lg-4 bg-light padding-40">
                            <h3>Add FAQ's</h3>
                            <hr />
                            <div className="form-group">
                                <label>Question</label>
                                <input name='question' value={data.question} onChange={questionChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter your question " />
                            </div>
                            <div className="form-group">
                                <label>Answer</label>
                                <TextEditor name="answer" value={data.answer} onChange={answerChangeHandler} />
                            </div>
                            <div className="form-group">
                                {
                                    editMode === true ? (
                                        <button type='button' onClick={editSaveHandler} className="btn-orange-sm border-none cursor-pointer">Save Edit</button>
                                    ) : (
                                        <button type='submit' className="btn-orange-sm border-none cursor-pointer">Submit</button>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
