/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

export default function Users() {

  const backendURL = process.env.REACT_APP_SERVER_URL;
  const authSlice = useSelector((state) => state.auth);
  const navigate = useNavigate();

  if (authSlice.adminType === null || authSlice.adminType !== 'super') {
    navigate('/')
  }

  const defaultState = {
    name: '',
    email: '',
    password: '',
    phone: '',
    adminType: '',
  }

  const [user, setUser] = useState(defaultState);
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState({});
  const [editMode, setEditMode] = useState(false);

  const getUsers = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${backendURL}/api/users`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        setUsers(res.data.data);
        toast.success("Data fetched successfully!")
      } else {
        throw new Error()
      }
    } catch (error) {
      toast.error("Couldn't fetch the data!")
    }
  }

  const inputChangeHandler = (event) => {
    if (editMode === true) {
      setEditData((prevState) => {
        return {
          ...prevState,
          [event.target.name]: event.target.value
        }
      })
    }
    setUser((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value
      }
    })
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    try {

      const res = await axios({
        method: "POST",
        url: `${backendURL}/api/users`,
        headers: {
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${authSlice.token}`
        },
        data: user
      })

      if (res && res.status === 200) {
        const newData = [...users];
        newData.push(res.data.data);
        setUsers(newData);
        toast.success("Data added successfully!")
      } else {
        throw new Error()
      }

    } catch (error) {
      console.log(error)
      toast.error("Couldn't create the data")
    }

    setUser(defaultState);
  }

  const editHandler = (obj, idx) => {
    setUser({
      ...obj, idx
    });
    setEditMode(true);
    setEditData({});
  }

  const saveEditHandler = async () => {

    try {
      const res = await axios({
        method: "PATCH",
        url: `${backendURL}/api/users/${user._id}`,
        headers: {
          "Content-Type": "Application/json",
          "Authorization": `Bearer ${authSlice.token}`
        },
        data: editData
      })

      if (res && res.status === 200) {

        const newUsers = [...users];
        newUsers[user.idx] = res.data.data
        setUsers(newUsers);
        toast.success("Data updated successfully!")
      } else {
        throw new Error();
      }

    } catch (error) {
      toast.error("Couldn't update the data!")
    }

    setUser(defaultState);
    setEditData({});
    setEditMode(false);

  }

  const deleteHandler = async (id) => {

    if (!id || id.length === 0) {
      toast.error("No ID");
    }

    try {
      const res = await axios({
        method: "DELETE",
        url: `${backendURL}/api/users/${id}`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        const newUsers = users.filter((item) => item._id !== id);
        setUsers(newUsers);
        toast.success("Data deleted successfully!")
      }
    } catch (error) {
      toast.error("Couldn't delete the data!")
    }

  }

  useEffect(() => {
    getUsers();
  }, [])

  return (
    <Fragment>
      <div className="data-box mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Manage Admins</h2>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">

              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Admin Type</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users && users.length !== 0 && users.map((item, idx) => {
                      return (
                        <tr key={item._id} >
                          <td>{idx + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.adminType}</td>
                          <td className="text-center">
                            <button onClick={() => editHandler(item, idx)} type='button' className="btntag-edit">Edit</button>
                            <button type='button' onClick={() => deleteHandler(item._id)} className="btntag-danger">Delete</button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <form onSubmit={submitHandler} className="col-lg-4 bg-light padding-40">
              <h3>{editMode === true ? "Edit" : "Add"} Admin</h3>
              <hr />
              <div className="form-group">
                <label>Admin Type</label>
                <select value={user.adminType} onChange={inputChangeHandler} name='adminType' className="form-control shadow-none">
                  <option>Select Admin Type</option>
                  <option value="super">Super Admin</option>
                  <option value="data">Data Admin</option>
                </select>
              </div>
              <div className="form-group">
                <label>Name</label>
                <input name='name' value={user.name} onChange={inputChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Name" />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input name='email' value={user.email} onChange={inputChangeHandler} type="email" className="form-control shadow-none" placeholder="Enter Email" />
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input name='phone' value={user.phone} onChange={inputChangeHandler} type="tel" className="form-control shadow-none" placeholder="Enter Phone" />
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="d-flex">
                  <input id='user-pass' name='password' value={user.password} onChange={inputChangeHandler} type="password" className="form-control shadow-none" placeholder="Enter Password" />
                  <button type='button' onClick={() => {
                    const passInput = document.getElementById('user-pass');
                    if (passInput.type === 'password') {
                      passInput.type = 'text'
                    } else {
                      passInput.type = 'password'
                    }
                  }} className="btntag-purp" style={{ cursor: "pointer", border: "none" }}><i className="fa fa-eye-slash" aria-hidden="true"></i></button>
                </div>
              </div>
              <div className="form-group">
                {
                  editMode === true ? (
                    <button type='button' onClick={saveEditHandler} className="btn-orange-sm border-none cursor-pointer">Edit</button>
                  ) : (
                    <button type='submit' className="btn-orange-sm border-none cursor-pointer">Submit</button>
                  )
                }
              </div>

            </form>
          </div>
        </div>
      </div >
    </Fragment >
  )
}
