/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast';

import ImagePicker from '../../../Components/ImagePicker/ImagePicker'
import { useSelector } from 'react-redux';

export default function FloorPlan() {

  const serverURL = process.env.REACT_APP_SERVER_URL;

  const defaultState = {
    name: '',
    type: '',
    parentLocation: '',
    image: null,
    metaTitle: '',
    metaDescription: '',
    metaKeyWords: '',
  }

  const [locations, setLocations] = useState([]);
  const [currItem, setCurrItem] = useState(defaultState);
  const authSlice = useSelector((state) => state.auth);

  const getData = async () => {

    try {
      const res = await axios({
        method: 'GET',
        url: `${serverURL}/api/location`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })
      if (res && res.status === 200) {
        const { data } = res.data;
        setLocations(data);
      }
    } catch (error) {
      if (error) {
        toast.error("Couldn't fetch the data")
      }
    }
  }

  const createData = async (event) => {
    event.preventDefault();

    if (currItem.name === '') {
      return toast.error("Please provide a name")
    }

    if (currItem.type === '') {
      return toast.error("Please provide a type")
    }

    if (currItem.type !== 'main-location' && currItem.parentLocation === '') {
      return toast.error("Please provide a parent")
    }

    try {
      const formData = new FormData();

      for (let key in currItem) {
        if (currItem[key] !== null) {
          formData.append(key, currItem[key])
        }
      }

      const res = await axios({
        method: 'POST',
        url: `${serverURL}/api/location`,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${authSlice.token}`
        },
        data: formData
      })

      if (res && res.status === 200) {
        getData();
      }
      setCurrItem(defaultState);
      toast.success("Successfully created the data")
    } catch (error) {
      toast.error("Couldn't create the data")
    }
  }

  const deleteData = async (id) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `${serverURL}/api/location/${id}`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        let newData = [...locations];
        newData = newData.filter((item) => item._id !== id);
        setLocations(newData);
      }
    } catch (error) {
      toast.error("Couldn't delete the data")
    }
  }

  const textChangeHandler = (event) => {
    const { value, name } = event.target;

    setCurrItem((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const typeChangeHandler = (event) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        type: event.target.value
      }
    })
  }

  const parentChangeHandler = (event) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        parentLocation: event.target.value
      }
    })
  }

  const imageChangeHandler = (file) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        image: file
      }
    })
  }


  useEffect(() => {
    getData();
  }, [])

  return (
    <Fragment>
      <div className="data-box mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Locations</h2>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">

              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Location Name</th>
                    <th>Type</th>
                    <th>Parent Location Name</th>
                    <th>LocationImage</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    locations.map((location, idx) => {
                      console.log(location);
                      return (
                        <tr key={location._id} >
                          <td>{idx + 1}</td>
                          <td>{location.name}</td>
                          <td>{location.type}</td>
                          <td>{location.type === 'main-location' ? 'NA' : location.parentLocation.name}</td>
                          <td>
                            {location.image && <img width='100px' src={`${serverURL}/${location.image}`} alt="location" />}
                          </td>
                          <td>
                            <div className='flex' >
                              <button className="btntag-danger" onClick={() => deleteData(location._id)}  >DELETE</button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <form className="col-lg-4 bg-light padding-40" onSubmit={createData}>
              <h3>Add Location</h3>
              <hr />
              <div className="form-group">
                <label>Location Name</label>
                <input autoComplete='off' name='name' value={currItem.name} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Location Name" />
              </div>
              <div className="form-group">
                <label>Type</label>
                <select value={currItem.type} className="form-control shadow-none" onChange={typeChangeHandler}>
                  <option value='' >Type ?</option>
                  <option key='main-location' value='main-location'>Main Location</option>
                  <option key='main-zone' value='main-zone'>Main Zone</option>
                  <option key='sub-zone' value='sub-zone'>Sub Zone</option>
                </select>
              </div>
              {
                currItem.type === 'main-zone' && (
                  <div className="form-group">
                    <label>Select Parent Main Location</label>
                    <select value={currItem.parentLocation} className="form-control shadow-none" onChange={parentChangeHandler}>
                      <option value='' >Parent ?</option>
                      {
                        locations.map((location) => {
                          if (location.type === 'main-location') {
                            return (
                              <option key={location._id} value={location._id}>{location.name}</option>
                            )
                          }
                          return null;
                        })
                      }
                    </select>
                  </div>
                )
              }
              {
                currItem.type === 'sub-zone' && (
                  <div className="form-group">
                    <label>Select Parent Main Zone</label>
                    <select value={currItem.parentLocation} className="form-control shadow-none" onChange={parentChangeHandler}>
                      <option value='' >Parent ?</option>
                      {
                        locations.map((location) => {
                          if (location.type === 'main-zone') {
                            return (
                              <option key={location._id} value={location._id}>{location.name}</option>
                            )
                          }
                          return null;
                        })
                      }
                    </select>
                  </div>
                )
              }
              <div className="form-group">
                <div className="image-container mt-10">
                  {currItem.name && <ImagePicker onChange={imageChangeHandler} id='image' title='Location Image' />}
                </div>
              </div>
              <div className="form-group">
                <label>Meta Title</label>
                <input autoComplete='off' name='metaTitle' value={currItem.metaTitle} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Meta Title" />
              </div>
              <div className="form-group">
                <label>Meta Description</label>
                <input autoComplete='off' name='metaDescription' value={currItem.metaDescription} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Meta Description" />
              </div>
              <div className="form-group">
                <label>Meta Keywords</label>
                <input autoComplete='off' name='metaKeyWords' value={currItem.metaKeyWords} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Key Words" />
              </div>
              <div className="form-group">
                <button type='submit' className="btn-orange-sm border-none cursor-pointer">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
