/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import axios from 'axios'

export default function ScheduleMeeting() {

    const backendURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth);

    const [meetings, setMeetings] = useState([]);
    // const [currMeeting, setCurrMeeting] = useState();

    const getMeetings = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${backendURL}/api/schedule-meeting`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                setMeetings(res.data.data);
            } else {
                throw new Error();
            }
        } catch (error) {

        }
    }

    const deleteHandler = async (id) => {

        if (!id || id.length === 0) {
            return toast.error('No ID');
        }

        try {
            const res = await axios({
                method: "DELETE",
                url: `${backendURL}/api/schedule-meeting/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                const newData = meetings.filter((item) => item._id !== id);
                setMeetings(newData);
                toast.success("Data deleted successfully!")
            }
        } catch (error) {
            toast.error("Couldn't delete the data")
        }

    }

    useEffect(() => {
        getMeetings();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Scheduled Meetings</h2>
                                <div style={{ display: 'none' }} className="form-inline">
                                    <div className="form-group">
                                        <label className="mr-2">From</label>
                                        <input type="date" className="form-control shadow-none" />
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2 ml-3">To</label>
                                        <input type="date" className="form-control shadow-none" />
                                    </div>
                                </div>
                                {/*<Link to={"/#"} className="btn-orange-sm">Download CSV File</Link>*/}
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Property</th>
                                        <th>Location</th>
                                        <th>Address</th>
                                        <th>Time</th>
                                        <th>Date of Meeting</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        meetings && meetings.length !== 0 && meetings.map((item, idx) => {

                                            let dateOfMetting = new Date(item.date)

                                            dateOfMetting = `${dateOfMetting.getDate()}/${dateOfMetting.getMonth() + 1}/${dateOfMetting.getFullYear()}`

                                            let createdAt = new Date(item.createdAt)

                                            createdAt = `${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`

                                            return (
                                                <tr>
                                                    <td>{idx + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.property}</td>
                                                    <td>{item.location}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.time}</td>
                                                    <td>{dateOfMetting}</td>
                                                    <td>{createdAt}</td>
                                                    <td className="text-center">
                                                        <button onClick={() => deleteHandler(item._id)} className="btntag-danger">Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
