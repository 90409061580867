import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Sidebar() {

  const authSlice = useSelector((state) => state.auth)

  return (
    <>
      <div className="sidebar left ">
        <div className="user-panel">
          <div className="pull-left image">
            <img src='/images/160x160.png' className="rounded-circle" alt="Realmark Admin" />
          </div>
          <div className="pull-left info">
            <p>{authSlice.adminType} admin</p>
            <a href="/#"><i className="fa fa-circle text-success"></i> Online</a>
          </div>
        </div>
        <ul className="list-sidebar">
          <li> <Link to="/" className="active"><span className="nav-label"><i className="fa fa-home"></i>  Dashboard</span></Link> </li>
          <li> <Link to="/#" data-toggle="collapse" data-target="#manage-listing" className="collapsed" > <span className="nav-label"><i className="fa fa-th-large"></i> Manage Listing </span> <span className="fa fa-chevron-down aero"></span> </Link>
            <ul className="sub-menu collapse" id="manage-listing">
              <li><Link to="/property-listing">Property Listing</Link></li>
              <li><Link to="/amenities">Amenities</Link></li>
              <li><Link to="/category">Category</Link></li>
              <li><Link to="/property-unit">Property Unit</Link></li>
              <li><Link to="/floor-plan">Floor Plan</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
              <li><Link to="/location">Location</Link></li>
              <li><Link to="/property-video">Property Video</Link></li>
            </ul>
          </li>
          <li> <Link to="/#" data-toggle="collapse" data-target="#career" className="collapsed" > <span className="nav-label"><i className="fa fa-suitcase"></i> Career </span> <span className="fa fa-chevron-down aero"></span> </Link>
            <ul className="sub-menu collapse" id="career">
              <li><Link to="/job-post">Job Post</Link></li>
              <li><Link to="/job-application">Job Application</Link></li>
            </ul>
          </li>
          <li> <Link to="/#" data-toggle="collapse" data-target="#lead-forms" className="collapsed" > <span className="nav-label"><i className="fa fa-wpforms"></i> Inquiry Leads </span> <span className="fa fa-chevron-down aero"></span> </Link>
            <ul className="sub-menu collapse" id="lead-forms">
              <li><Link to="/contact-form-leads">Contact & Grievance  Form</Link></li>
              <li><Link to="/property-inquiry">Properties Inquiry</Link></li>
              <li><Link to="/meetings">Meetings</Link></li>
              <li><Link to="/subscriber">Subscribers</Link></li>
            </ul>
          </li>
          <li> <Link to="/#" data-toggle="collapse" data-target="#about" className="collapsed" > <span className="nav-label"><i className="fa fa-compass"></i> About Us </span> <span className="fa fa-chevron-down aero"></span> </Link>
            <ul className="sub-menu collapse" id="about">
              <li><Link to="/growth-timeline">Growth Timeline</Link></li>
              <li><Link to="/team-members">Team Members</Link></li>
              <li><Link to="/channel-partner">Channel Partners</Link></li>
            </ul>
          </li>
          <li> <Link to="/testimonials" className=""><span className="nav-label"><i className="fa fa-star"></i>  Testimonials</span></Link> </li>
          <li> <Link to="/contact" className=""><span className="nav-label"><i className="fa fa-address-book"></i>  Contact Details</span></Link> </li>
          <li> <Link to="/faq" className=""><span className="nav-label"><i className="fa fa-question-circle-o"></i>  FAQ's</span></Link> </li>
          <li> <Link to="/disclaimer" className=""><span className="nav-label"><i className="fa fa-paperclip"></i>  Disclaimer</span></Link> </li>
          <li> <Link to="/privacy-policy" className=""><span className="nav-label"><i className="fa fa-file-powerpoint-o"></i>  Privacy Policy</span></Link> </li>
          {
            authSlice.adminType && authSlice.adminType === 'super' && (
              <li> <Link to="/manage-admins" className=""><span className="nav-label"><i className="fa fa-lock"></i>  Manage Admins</span></Link> </li>
            )
          }
        </ul>
      </div>
    </>
  )
}
