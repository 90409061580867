/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

export default function ContactForm() {

    const backendURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth);

    const [leads, setLeads] = useState([]);

    const get = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${backendURL}/api/contact-form`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                const resData = res.data.data.filter((item) => item.type === 'property-inquiry')
                setLeads(resData);
                toast.success("Data fetched successfully!")
            }

        } catch (error) {
            toast.error("Couldn't fetch the data")
        }
    }

    const deleteHandler = async (id) => {

        if (!id || id.length === 0) {
            return toast.error('No ID');
        }

        try {
            const res = await axios({
                method: "DELETE",
                url: `${backendURL}/api/contact-form/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                const newData = leads.filter((item) => item._id !== id);
                setLeads(newData);
                toast.success("Data deleted successfully!")
            }
        } catch (error) {
            toast.error("Couldn't delete the data")
        }

    }

    useEffect(() => {
        get();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Property Inquiry Leads</h2>
                                <div style={{ display: 'none' }} className="form-inline">
                                    <div className="form-group">
                                        <label className="mr-2">From</label>
                                        <input type="date" className="form-control shadow-none" />
                                    </div>
                                    <div className="form-group">
                                        <label className="mr-2 ml-3">To</label>
                                        <input type="date" className="form-control shadow-none" />
                                    </div>
                                </div>
                                <Link style={{ display: 'none' }} to={"/#"} className="btn-orange-sm">Download CSV File</Link>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <table className="table table-bordered table-striped table-hover">
                                <tr>
                                    <th>SR No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Message</th>
                                    <th>Lead Source</th>
                                    <th className="text-center">Date</th>
                                    <th className="text-center">Action</th>
                                </tr>
                                {
                                    leads && leads.length !== 0 && (
                                        <tbody>
                                            {
                                                leads.map((lead, idx) => {
                                                    return (
                                                        <tr>
                                                            <td>{idx + 1}</td>
                                                            <td>{lead.name}</td>
                                                            <td>{lead.email}</td>
                                                            <td>{lead.phone}</td>
                                                            <td>{lead.message && lead.message.length !== 0 ? lead.message : 'No message'}</td>
                                                            <td>{lead.source}</td>
                                                            <td className='text-center' >{lead.createdAt.split('T')[0]}</td>
                                                            <td className="text-center">
                                                                <button onClick={() => deleteHandler(lead._id)} className="btntag-danger">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
