import { configureStore } from '@reduxjs/toolkit'
import propertyReducer from "./propertySlice";
import faqSliceReducer from './faqSlice'
import authSliceReducer from './authSlice'


const store = configureStore({
    reducer: { property: propertyReducer, faq: faqSliceReducer, auth: authSliceReducer }
})

export default store;