/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast'
import axios from 'axios'
import { useSelector } from 'react-redux';

export default function PropertyUnit() {

  const serverURL = process.env.REACT_APP_SERVER_URL

  const authSlice = useSelector((state) => state.auth);

  const defaaultUnitState = {
    name: '',
    property: ''
  }

  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [currItem, setCurrItem] = useState(defaaultUnitState)

  const getProperties = async () => {

    try {
      const res = await axios({
        method: 'GET',
        url: `${serverURL}/api/property`,
      })
      if (res && res.status === 200) {
        const { data } = res.data;
        setProperties(data);
      }

    } catch (error) {
      toast.error("Couldn't fetch the data!")
    }


  }

  const getUnits = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${serverURL}/api/units`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        const { data } = res.data;
        setUnits(data);
      }
    } catch (error) {
      toast.error("Couldn't fetch the data!")
    }
  }

  const postData = async (event) => {
    event.preventDefault();

    const res = await axios({
      method: 'POST',
      url: `${serverURL}/api/units`,
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${authSlice.token}`
      },
      data: {
        ...currItem,
        property: currItem.property.split('-')[0]
      }
    })

    if (res && res.status === 200) {
      const newUnits = [...units];
      newUnits.push({
        ...currItem,
        property: {
          title: currItem.property.split('-')[1]
        },
        _id: res.data.data._id
      });
      setUnits(newUnits);
    }

    setCurrItem(defaaultUnitState)
  }

  const deleteData = async (id) => {

    if (!id) {
      return toast.error('Please provide and ID')
    }

    try {
      const resFloorPlans = await axios({
        method: "DELETE",
        url: `${serverURL}/api/floor-plan/unit/${id}`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`,
        }
      })

      if (!resFloorPlans || resFloorPlans.status !== 200) {
        toast.error("Couldn't delete the data");
      }
    } catch (error) {
      toast.error("Couldn't delete the data!")
    }

    try {
      const res = await axios({
        method: 'DELETE',
        url: `${serverURL}/api/units/${id}`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`,
        }
      })

      if (res && res.status === 200) {
        let newData = [...units];
        newData = newData.filter((item) => item._id !== id);
        setUnits(newData);
        toast.success("Successfully deleted the data")
      }
    } catch (error) {
      toast.error("Couldn't delete the data!")
    }

  }

  const nameInputChangeHandler = (event) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        name: event.target.value
      }
    })
  }

  const propertyInputChangeHandler = (event) => {
    setCurrItem((prevState) => {
      return {
        ...prevState,
        property: event.target.value
      }
    })
  }

  useEffect(() => {
    getProperties();
    getUnits();
  }, [])

  return (
    <Fragment>
      <div className="data-box mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Property Units</h2>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Property Name</th>
                    <th>BHK Type</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    units && units.map((unit, idx) => {
                      return (
                        <tr key={unit._id} >
                          <td>{idx + 1}</td>
                          <td>{unit.property.name}</td>
                          <td>{unit.name}</td>
                          <td className="text-center"><button onClick={() => deleteData(unit._id)} className="btntag-danger">Delete</button></td>
                        </tr>
                      )
                    })
                  }</tbody>
              </table>
            </div>
            <form onSubmit={postData} className="col-lg-4 bg-light padding-40">
              <h3>Add Property Units</h3>
              <hr />
              <div className="form-group">
                <label>Property Name</label>
                <select className="form-control shadow-none" onChange={propertyInputChangeHandler} value={currItem.property}>
                  <option value="">Property ?</option>
                  {
                    properties.map((property) => {
                      return (
                        <option key={property._id} value={`${property._id}-${property.title}`}>{property.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="form-group">
                <label>BHK Type</label>
                <input onChange={nameInputChangeHandler} value={currItem.name} autoComplete='off' type="text" className="form-control shadow-none" placeholder="Enter BHK For E.g. 2 BHK" />
              </div>
              <div className="form-group">
                <button className="btn-orange-sm border-none cursor-pointer">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
