import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    question: '',
    answer: '',
    showModal: false
}

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        setValues(state, action) {
            const { payload } = action;
            state.question = payload.question;
            state.answer = payload.answer;
        },
        openModal(state) {
            state.showModal = true;
        },
        closeModal(state) {
            state.showModal = false;
        }
    }
})

export const faqActions = faqSlice.actions;

export default faqSlice.reducer;