/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

export default function JobApplication() {

    const backendURL = process.env.REACT_APP_SERVER_URL;

    const [jobApplications, setJobApplications] = useState([]);
    const authSlice = useSelector((state) => state.auth);

    const get = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${backendURL}/api/job-application`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                setJobApplications(res.data.data);
                toast.success("Data fetched successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't fetch the data!")
        }

    }

    const deleteHandler = async (id) => {
        if (!id) {
            return toast.error("Not ID")
        }

        try {

            const res = await axios({
                method: "DELETE",
                url: `${backendURL}/api/job-application/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                const newData = jobApplications.filter((item) => item._id !== id);
                setJobApplications(newData);
                toast.success("Data deleted successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't delete the data!")
        }

    }

    useEffect(() => {
        get();
    }, [])

    useEffect(() => {
        console.log(jobApplications)
    }, [jobApplications])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Job Applications</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">

                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Job Role</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        jobApplications && jobApplications.length !== 0 && jobApplications.map((jobApplication, idx) => {
                                            return (
                                                <tr key={jobApplication._id} >
                                                    <td>{idx + 1}</td>
                                                    <td>{jobApplication.role}</td>
                                                    <td>{jobApplication.name}</td>
                                                    <td>{jobApplication.email}</td>
                                                    <td>{jobApplication.phone}</td>
                                                    <td className="text-center">
                                                        <a target='_blank' href={`${backendURL}/${jobApplication.cv}`} className="btntag-edit" rel="noreferrer">
                                                            Download CV
                                                        </a>
                                                        <button onClick={() => deleteHandler(jobApplication._id)} className="btntag-danger">Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
