import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../store/authSlice';



export default function Header() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(authActions.logout());
    navigate('/login');
  }

  return (
    <Fragment>
      <header className="header">
        <nav className="navbar navbar-toggleable-md navbar-light">
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand p-0 mr-5" href="/">
            <img src={`${process.env.REACT_APP_FRONTEND_URL}/images/logo.png`} className="real-logo" alt="" />
          </a>

          <div className="collapse navbar-collapse flex-row-reverse" id="navbarNavDropdown">
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item dropdown  user-menu">
                <button type='button' onClick={logoutHandler} className="btn-orange-sm d-flex align-items-center" href="/#">
                  <i className="fa fa-power-off mr-1"></i> Logout
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </Fragment>
  )
}
