/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { toast, Toaster } from 'react-hot-toast';

import Header from './Admin_Components/Global_Components/Header';
import Footer from './Admin_Components/Global_Components/Footer';
import Sidebar from './Admin_Components/Global_Components/Sidebar';
import Dashboard from './Admin_Components/Dashboard/Dashboard';
import AmenitiesListing from './Admin_Components/Manage_Listing/Amenities/Amenities';
import Category from './Admin_Components/Manage_Listing/Category/Category';
import ChannelPartners from './Admin_Components/Manage_Listing/ChannelPartners/ChannelPartners';
import FloorPlanListing from './Admin_Components/Manage_Listing/Floor_Plan/FloorPlan';
import PropertyUnitListing from './Admin_Components/Manage_Listing/Property_Unit/PropertyUnit';
import PropertyListing from './Admin_Components/Manage_Listing/Property_Listing/PropertyListing';
import AddPropertyListing from './Admin_Components/Manage_Listing/Property_Listing/AddPropertyListing';
import EditPropertyListing from './Admin_Components/Manage_Listing/Property_Listing/EditPropertyListing';
import Location from './Admin_Components/Manage_Listing/Location/Location';
import Amenities from './Admin_Components/Manage_Listing/Amenities/Amenities';
import PropertyUnit from './Admin_Components/Manage_Listing/Property_Unit/PropertyUnit';
import FloorPlan from './Admin_Components/Manage_Listing/Floor_Plan/FloorPlan';
import PrivacyPolicy from './Admin_Components/Privacy_Policy/PrivacyPolicy';
import Disclaimer from './Admin_Components/Disclaimer/Disclaimer';
import Faq from './Admin_Components/Faqs/Faq';
import Contact from './Admin_Components/Contact_Details/Contact';
import ContactForm from './Admin_Components/Inquiry_Leads/ContactForm';
import ScheduleMeeting from './Admin_Components/Inquiry_Leads/ScheduleMeeting';
import PropertyEnquiry from './Admin_Components/Inquiry_Leads/PropertyEnquiry';
import Subscriber from './Admin_Components/Subscriber/Subscriber'
import JobPost from './Admin_Components/Career/JobPost';
import JobApplication from './Admin_Components/Career/JobApplication';
import Testimonials from './Admin_Components/Testimonials/Testimonials';
// import CompanyProfile from './Admin_Components/About_Us/CompanyProfile';
import GrowthTimeline from './Admin_Components/About_Us/GrowthTimeline';
import Team from './Admin_Components/About_Us/Team';
import Users from './Admin_Components/Manage_Admins/Users';
import Gallery from './Admin_Components/Manage_Listing/Gallery/Gallery'
import BackDrop from './Components/Modal/BackDrop';
import Modal from './Components/Modal/Modal';

import { authActions } from './store/authSlice';
import Login from './Admin_Components/Login/Login';
import axios from 'axios';
import NotFound from './Admin_Components/Manage_Listing/NotFound';
import PropertyVideo from './Admin_Components/Manage_Listing/PropertyVideo/PropertyVideo';

function App() {
  const backendURL = process.env.REACT_APP_SERVER_URL;

  const faq = useSelector((state) => state.faq);
  const dispatch = useDispatch();
  const authSlice = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const veriyfyToken = async (token) => {
    try {
      const res = await axios({
        method: "POST",
        url: `${backendURL}/api/auth/verify-token`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          token
        }
      })

      if (res && res.status === 200) {
        dispatch(authActions.login({ token: token, adminType: res.data.adminType }));
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error('Please login again');
      dispatch(authActions.logout());
      navigate('/login')
    }
  }

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token && token.length !== 0) {
      veriyfyToken(token);
    } else {
      navigate('/login')
    }
  }, [])

  return (
    <div className="App">
      <Toaster
        position="bottom-center"
        containerClassName="toast-popup"
        reverseOrder={false}
        toastOptions={{
          className: 'toast-popup',
          style: {
            fontSize: '1.2rem',
          }
        }}
      />
      {
        faq.showModal && faq.showModal === true && (
          <BackDrop>
            <Modal />
          </BackDrop>
        )
      }
      {authSlice.isLoggedIn === true && <Header />}
      <div className="main">
        {authSlice.isLoggedIn === true && <Sidebar />}
        <Routes>
          {authSlice.isLoggedIn === false && <Route exact path='/login' element={<Login />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/' element={<Dashboard />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/property-listing' element={<PropertyListing />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/amenities' element={<AmenitiesListing />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/floor-plan' element={<FloorPlanListing />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/property-unit' element={<PropertyUnitListing />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/add-property-listing' element={<AddPropertyListing />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/edit-property-listing/:propertyId' element={<EditPropertyListing />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/amenities' element={<Amenities />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/property-unit' element={<PropertyUnit />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/floor-plan' element={<FloorPlan />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/disclaimer' element={<Disclaimer />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/faq' element={<Faq />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/contact' element={<Contact />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/contact-form-leads' element={<ContactForm />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/meetings' element={<ScheduleMeeting />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/property-inquiry' element={<PropertyEnquiry />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/job-post' element={<JobPost />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/job-application' element={<JobApplication />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/growth-timeline' element={<GrowthTimeline />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/team-members' element={<Team />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/testimonials' element={<Testimonials />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/manage-admins' element={<Users />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/gallery' element={<Gallery />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/property-video' element={<PropertyVideo />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/category' element={<Category />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/channel-partner' element={<ChannelPartners />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/location' element={<Location />} />}
          {authSlice.isLoggedIn === true && <Route exact path='/subscriber' element={<Subscriber />} />}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      {authSlice.isLoggedIn === true && <Footer />}
    </div>
  );
}

export default App;