/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast'

export default function JobPost() {

    const serverURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth);

    const defaultState = {
        role: '',
        location: '',
        experience: '',
        qualification: '',
        description: ''
    }

    const [jobPosts, setJobPosts] = useState([]);
    const [data, setData] = useState(defaultState);
    const [editMode, setEditMode] = useState(false);

    const textChangeHandler = (event) => {
        setData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const getAll = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${serverURL}/api/job-post`
            })

            if (res && res.status === 200) {
                setJobPosts(res.data.data);
                toast.success("Data fetched successfully!")
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't fetch the data")
        }
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        try {

            const res = await axios({
                method: "POST",
                url: `${serverURL}/api/job-post`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data
            })

            if (res && res.status === 200) {
                const newData = [...jobPosts];
                newData.push({
                    role: res.data.data.role,
                    location: res.data.data.location,
                    experience: res.data.data.experience,
                    qualification: res.data.data.qualification,
                    description: res.data.data.description,
                    _id: res.data.data._id
                })

                setJobPosts(newData);
                setData(defaultState);
                toast.success('Data created successfully!')
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't create the data")
        }

    }

    const editHandler = async (post, idx) => {
        setData({
            location: post.location,
            role: post.role,
            qualification: post.qualification,
            experience: post.experience,
            description: post.description,
            _id: post._id,
            idx
        });
        setEditMode(true);
    }

    const editSaveHandler = async () => {
        try {
            const res = await axios({
                method: "PATCH",
                url: `${serverURL}/api/job-post/${data._id}`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: {
                    ...data,
                    _id: undefined,
                    idx: undefined
                }
            })

            if (res && res.status === 200) {
                const newData = [...jobPosts];
                newData[data.idx] = {
                    role: res.data.data.role,
                    location: res.data.data.location,
                    experience: res.data.data.experience,
                    qualification: res.data.data.qualification,
                    description: res.data.data.description,
                    _id: res.data.data._id
                };
                setJobPosts(newData);
                setData(defaultState);
                setEditMode(false);
                toast.success('Data edited successfully!')
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't create the data")
        }
    }

    const deleteHandler = async (id) => {

        try {
            const res = await axios({
                method: 'DELETE',
                url: `${serverURL}/api/job-post/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`

                }
            });

            if (res && res.status === 200) {
                const newPosts = jobPosts.filter((item) => item._id !== id);
                setJobPosts(newPosts);
                toast.success('Data deleted successfully!')
            } else {
                throw new Error();
            }
        } catch (error) {
            toast.error("Couldn't delete the data")
        }

    }

    useEffect(() => {
        getAll();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Job Posts</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">

                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Job Role</th>
                                        <th>Location</th>
                                        <th>Experience</th>
                                        <th>Qualification</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        jobPosts.map((jobPost, idx) => {
                                            return (
                                                <tr key={jobPost._id} >
                                                    <td>{idx + 1}</td>
                                                    <td>{jobPost.role}</td>
                                                    <td>{jobPost.location}</td>
                                                    <td>{jobPost.experience}</td>
                                                    <td>{jobPost.qualification}</td>
                                                    <td className="text-center">
                                                        <button onClick={() => editHandler(jobPost, idx)} className="btntag-edit">Edit</button>
                                                        <button onClick={() => deleteHandler(jobPost._id)} className="btntag-danger">Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <form className="col-lg-4 bg-light padding-40" onSubmit={submitHandler} >
                            <h3>Add Job Posts</h3>
                            <hr />
                            <div className="form-group">
                                <label>Job Role</label>
                                <input name='role' onChange={textChangeHandler} value={data.role} type="text" className="form-control shadow-none" placeholder="Enter Job Role" />
                            </div>
                            <div className="form-group">
                                <label>Location</label>
                                <input name='location' onChange={textChangeHandler} value={data.location} type="text" className="form-control shadow-none" placeholder="Enter Location" />
                            </div>
                            <div className="form-group">
                                <label>Experience</label>
                                <input name='experience' onChange={textChangeHandler} value={data.experience} type="text" className="form-control shadow-none" placeholder="Enter Experience" />
                            </div>
                            <div className="form-group">
                                <label>Qualification</label>
                                <input name='qualification' onChange={textChangeHandler} value={data.qualification} type="text" className="form-control shadow-none" placeholder="Enter Qualification" />
                            </div>
                            <div className="form-group">
                                <label>Job Description</label>
                                <textarea name='description' onChange={textChangeHandler} value={data.description} type="" className="form-control shadow-none" placeholder="Enter Job Description" />
                            </div>
                            <div className="form-group">
                                {
                                    editMode === true ? (
                                        <button type='button' onClick={editSaveHandler} className="btn-orange-sm border-none cursor-pointer">Save Edit</button>
                                    ) : (
                                        <button type='submit' className="btn-orange-sm border-none cursor-pointer">Submit</button>
                                    )
                                }
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
