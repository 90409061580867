/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

export default function MultiSelect(props) {

    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [defaultValues, setDefaultValues] = useState(null);

    const changeHandler = (value) => {
        const ids = [];

        value.forEach((item) => {
            ids.push(item.value)
        })

        setSelectedOptions(ids);
    }


    React.useEffect(() => {
        props.onChange(selectedOptions);
    }, [selectedOptions])

    useEffect(() => {
        const options = []
        props.inputOptions.forEach((item) => {
            options.push({
                value: item._id,
                label: item.name
            });
        })
        setOptions(options);
        if (props.selected) {
            const selectedOptions = options.filter((item) => props.selected.includes(item.value));
            setDefaultValues(selectedOptions)
            setSelectedOptions(props.selected)
        }
    }, [props.inputOptions])

    return (
        <div className='form-group' style={{ margin: '1rem 0' }} >
            {
                props.selected && defaultValues && defaultValues.length !== 0 && (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        defaultValue={defaultValues}
                        onChange={changeHandler}
                    />
                )
            }
            {
                props.selected && defaultValues && defaultValues.length === 0 && (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        onChange={changeHandler}
                    />
                )
            }
            {
                !props.selected && (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        onChange={changeHandler}
                    />
                )
            }
        </div>
    );
}