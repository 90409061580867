import axios from 'axios'
import { useSelector } from 'react-redux';
import './GalleryDisplay.css'

const GalleryDisplay = (props) => {

    const serverURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth);

    const deleteHandler = async () => {
        try {
            const res = await axios({
                method: 'DELETE',
                url: `${serverURL}/api/gallery-image/property/${props.propertyID}/image/${props.id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                props.refresh();
            }

        } catch (error) {
        }
    }

    return (
        <div className='gallery-display-item gallery-preview-box'>
            <img className='gallery-display-item-img img-fluid' src={props.src} alt='gallery' />
            <button onClick={deleteHandler} type='button' className='gallery-display-item-btn' ><ion-icon name="trash-bin"></ion-icon></button>
        </div>
    )

}

export default GalleryDisplay;