import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    token: '',
    adminType: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            state.token = action.payload.token;
            state.adminType = action.payload.adminType;
            state.isLoggedIn = true;
            if (!localStorage.getItem('token')) {
                localStorage.setItem('token', JSON.stringify(action.payload.token))
            }
        },
        logout(state) {
            state.token = ''
            state.isLoggedIn = false;
            state.adminType = null;
            localStorage.removeItem('token');
        }
    }
})

export const authActions = authSlice.actions;

export default authSlice.reducer;