/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const FilePicker = (props) => {

    const property = useSelector((state) => state.property)

    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const navigate = useNavigate();

    const fileChangeHandler = (event) => {
        setFile(event.target.files[0]);
    }

    const removeHandler = async () => {
        setFile(null);
        setPreview(null);
        document.getElementById(props.name).value = ''
        props.onDelete();
    }

    useEffect(() => {
        props.onChange(file);
    }, [file])

    const getExistingImages = async () => {
        if (property._id && property._id.length !== 0) {
            try {
                const pdf = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_SERVER_URL}/api/${props.name}/${property._id}`
                })
                setPreview(pdf.data.data.path);
            } catch (error) {
                if (!error.response || !error.response.status || !error.response.status === 404) {
                    toast.error('Something went wrong')
                    navigate('/property-listing')
                }
            }
        }
    }

    useEffect(() => {
        getExistingImages();
    }, [property])

    return (
        <div className="form-group">
            <label>{props.label}</label>
            <div>
                <input accept={props.accept} id={props.name} onChange={fileChangeHandler} type="file" className="form-control shadow-none" />
                {preview && <a className='btn-orange border-none cursor-pointer mr-3' style={{ marginRight: '10px', display: 'block', width: 'max-content' }} href={`${process.env.REACT_APP_SERVER_URL}/${preview}`} target='_blank' rel="noreferrer" ><i className='fa fa-file-pdf-o'></i>&nbsp;&nbsp;View PDF</a>}
                {preview && <button onClick={removeHandler} className='btntag-danger border-none mt-10' >Remove</button>}
            </div>
        </div>
    )

}

export default FilePicker