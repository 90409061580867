/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react';
import { propertyActions } from '../../../store/propertySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import TextEditor from '../../../Components/TextEditor/TextEditor';
import PlacesNearBy from '../../../Components/PlacesNearbyInput/FormPlacesNearByInput'
import axios from 'axios';
import { toast } from 'react-hot-toast';
import ImagePicker from '../../../Components/ImagePicker/ImagePicker';
import FilePicker from '../../../Components/FilePicker/FilePicker';
import MultiSelect from '../../../Components/MultiSelect/MultiSelect';
import { useState } from 'react';

export default function EditPropertyListing() {

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const property = useSelector((state) => state.property)
    const [thumbnail, setThumbnail] = useState(null);
    const [offer, setOffer] = useState(null);
    const [brochure, setBrochure] = useState(null);
    const [deleteThumbnail, setDeleteThumbnail] = useState(false);
    const [deleteOffer, setDeleteOffer] = useState(false);
    const [deleteBrochure, setDeleteBrochure] = useState(false);
    const authSlice = useSelector((state) => state.auth);
    const [categories, setCategories] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [locations, setLocations] = useState([]);


    const textChangeHandler = (event) => {
        if (event.target) {
            const { name, value } = event.target;
            dispatch(propertyActions.textValueChangeHandler({ name, value }))
        } else {
            const { name, value } = event;
            dispatch(propertyActions.textValueChangeHandler({ name, value }))
        }
    }

    const checkBoxChangeHandler = (event) => {
        const { name } = event.target;
        dispatch(propertyActions.checkValueChangeHandler({ name }))
    }

    const placesNearByHandler = (data) => {
        dispatch(propertyActions.placesNearByChangeHandler(data))
    }

    const thumbnailChangeHandler = (file) => {
        setThumbnail(file)
    }

    const offerChangeHandler = (file) => {
        setOffer(file)
    }

    const brochureChangeHandler = (file) => {
        setBrochure(file)
    }

    const thumbnailDeleteHandler = () => {
        setDeleteThumbnail(true)
    }

    const offerDeleteHandler = () => {
        setDeleteOffer(true)
    }

    const brochureDeleteHandler = () => {
        setDeleteBrochure(true)
    }

    const amenityChangeHandler = (selectedAmenities) => {
        dispatch(propertyActions.amenitiesChangeHandler(selectedAmenities))
    }

    const categoriesChangeHandler = (selectedAmenities) => {
        dispatch(propertyActions.categoriesChangeHandler(selectedAmenities))
    }

    const locationChangeHandler = (event) => {
        const { name, value } = event.target;

        if (name === "mainLocation") {
            dispatch(propertyActions.textValueChangeHandler({ name: 'mainZone', value: '' }));
            dispatch(propertyActions.textValueChangeHandler({ name: 'subZone', value: '' }));
        } else if (name === 'mainZone') {
            dispatch(propertyActions.textValueChangeHandler({ name: 'subZone', value: '' }));
        }

        dispatch(propertyActions.textValueChangeHandler({ name, value }));
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        let resProperty;
        try {
            resProperty = await axios({
                url: `${process.env.REACT_APP_SERVER_URL}/api/property/${params.propertyId}`,
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: property,
            })

            if (resProperty && resProperty.status === 200) {
            }
        } catch (error) {
            toast.error("Couldn't create property data")
            navigate('/property-listing')
            return;
        }

        let res;

        if (thumbnail !== null) {
            const thumbnailData = new FormData();

            thumbnailData.append('property', resProperty.data.data._id);
            thumbnailData.append('image', thumbnail)

            try {
                res = await axios({
                    url: `${process.env.REACT_APP_SERVER_URL}/api/thumbnail/`,
                    method: 'POST',
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                    data: thumbnailData,
                })

                if (res && res.status === 200) {
                } else {
                    throw new Error();
                }
            } catch (error) {
                toast.error("Couldn't create thumbnail data")
                navigate('/property-listing')
                return;
            }
        }

        if (deleteThumbnail === true) {
            try {

                const res = await axios({
                    method: 'DELETE',
                    url: `${process.env.REACT_APP_SERVER_URL}/api/thumbnail/${resProperty.data.data._id}`,
                    headers: {
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                })

                if (res && res.status === 200) {

                } else {
                    throw new Error();
                }

            } catch (error) {
                if (!error.response || !error.response.status || error.response.status !== 404) {
                    toast.error(`Couldn't delete thumbnail`)
                }
            }
        }

        if (offer !== null) {
            const offerData = new FormData();

            offerData.append('property', resProperty.data.data._id);
            offerData.append('image', offer)

            try {
                res = await axios({
                    url: `${process.env.REACT_APP_SERVER_URL}/api/offer/`,
                    method: 'POST',
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                    data: offerData,
                })

                if (res && res.status === 200) {
                } else {
                    throw new Error();
                }
            } catch (error) {
                toast.error("Couldn't create offer data")
                navigate('/property-listing')
                return;
            }
        }

        if (deleteOffer === true) {
            try {

                const res = await axios({
                    method: 'DELETE',
                    url: `${process.env.REACT_APP_SERVER_URL}/api/offer/${resProperty.data.data._id}`,
                    headers: {
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                })

                if (res && res.status === 200) {

                } else {
                    throw new Error();
                }

            } catch (error) {
                toast.error(`Couldn't delete offer`)
            }
        }

        if (brochure !== null) {
            const brochureData = new FormData();

            brochureData.append('property', resProperty.data.data._id);
            brochureData.append('brochure', brochure)

            try {
                res = await axios({
                    url: `${process.env.REACT_APP_SERVER_URL}/api/brochure/`,
                    method: 'POST',
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                    data: brochureData,
                })

                if (res && res.status === 200) {
                } else {
                    throw new Error();
                }
            } catch (error) {
                toast.error("Couldn't create brochure data")
                navigate('/property-listing')
                return;
            }
        }

        if (deleteBrochure === true) {
            try {

                const res = await axios({
                    method: 'DELETE',
                    url: `${process.env.REACT_APP_SERVER_URL}/api/brochure/${resProperty.data.data._id}`,
                    headers: {
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                })

                if (res && res.status === 200) {

                } else {
                    throw new Error();
                }

            } catch (error) {
                toast.error(`Couldn't delete offer`)
            }
        }

        toast.success('Successfully edited the data')
        navigate('/property-listing')
    }

    const getAllDetails = async () => {
        try {

            let res;

            res = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_SERVER_URL}/api/property/${params.propertyId}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                },
            })

            if (res && res.status === 200) {
                const propertyData = res.data.data;
                if (propertyData) {
                    toast.success(`Data fetched for: ${propertyData.name}`)
                    dispatch(propertyActions.setValues(propertyData))
                } else {
                    throw new Error()
                }
            }

        } catch (error) {
            toast.error('Something went wrong!');
            navigate('/property-listing')
        }

    }

    const getAmenities = async () => {
        const res = await toast.promise(
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_SERVER_URL}/api/amenities`,
            }), {
            loading: "Fetching...",
            success: "Data fetched successfully",
            error: "Couldn't fetch the data"
        }
        )

        if (res && res.status === 200) {
            const { data } = res.data;
            setAmenities(data)
        }
    }

    const getLocations = async () => {
        const res = await toast.promise(
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_SERVER_URL}/api/location`,
            }), {
            loading: "Fetching...",
            success: "Data fetched successfully",
            error: "Couldn't fetch the data"
        }
        )

        if (res && res.status === 200) {
            const { data } = res.data;
            setLocations(data)
        }
    }

    const getCategories = async () => {
        const res = await toast.promise(
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_SERVER_URL}/api/category`,
            }), {
            loading: "Fetching...",
            success: "Data fetched successfully",
            error: "Couldn't fetch the data"
        }
        )

        if (res && res.status === 200) {
            const { data } = res.data;
            setCategories(data)
        }
    }

    useEffect(() => {
        dispatch(propertyActions.setInitialState())
        getAllDetails();
        getAmenities();
        getLocations();
        getCategories();
        return () => {
            dispatch(propertyActions.setInitialState());
        }
    }, [])

    console.log(property)

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Edit Property</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <form className="col-lg-8" onSubmit={submitHandler} >
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Property Name</label>
                                        <input name='name' onChange={textChangeHandler} value={property.name} type="text" className="form-control shadow-none" placeholder="Enter Property Name" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Property Title</label>
                                        <input name='title' onChange={textChangeHandler} value={property.title} type="text" className="form-control shadow-none" placeholder="Enter Property Title" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Property Address</label>
                                        <input name='address' onChange={textChangeHandler} value={property.address} type="text" className="form-control shadow-none" placeholder="Enter Property Address" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>HIRA/RERA No.</label>
                                        <input name='hira' onChange={textChangeHandler} value={property.hira} type="text" className="form-control shadow-none" placeholder="Enter Registration No." />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Property Price (₹)</label>
                                        <input name='price' onChange={textChangeHandler} value={property.price} type="text" className="form-control shadow-none" placeholder="Enter Property Price" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Overview Title</label>
                                        <input name='overviewTitle' onChange={textChangeHandler} value={property.overviewTitle} type="text" className="form-control shadow-none" placeholder="Enter Overview Title" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    {
                                        locations && locations.length !== 0 && <div className="col-lg-4 mb-10">
                                            <label style={{ marginBottom: '0px' }}>Main Location</label>
                                            <select className='form-control shadow-none' name='mainLocation' onChange={locationChangeHandler} value={property.mainLocation._id ? property.mainLocation._id : property.mainLocation} >
                                                <option value='' >Select</option>
                                                {
                                                    locations.map((location) => {
                                                        if (location.type === 'main-location') {
                                                            return <option key={location._id} value={location._id} >{location.name}</option>
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                    {locations && locations.length !== 0 && <div className="col-lg-4 mb-10">
                                        <label style={{ marginBottom: '0px' }}>Main Zone</label>
                                        <select disabled={!property.mainLocation} className='form-control shadow-none' name='mainZone' onChange={locationChangeHandler} value={property.mainZone._id ? property.mainZone._id : property.mainZone} >
                                            <option value='' >Select</option>
                                            {
                                                locations.map((location) => {
                                                    if (location.type === 'main-zone' && (location.parentLocation._id === property.mainLocation || location.parentLocation._id === property.mainLocation._id)) {
                                                        return <option key={location._id} value={location._id}>{location.name}</option>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </select>
                                    </div>
                                    }
                                    {
                                        locations && locations.length !== 0 && <div className="col-lg-4 mb-10">
                                            <label style={{ marginBottom: '0px' }}>Sub Zone</label>
                                            <select disabled={!property.mainZone || !property.mainLocation} className='form-control shadow-none' name='subZone' onChange={locationChangeHandler} value={property.subZone._id ? property.subZone._id : property.subZone} >
                                                <option value='' >Select</option>
                                                {
                                                    locations && locations.length !== 0 && locations.map((location) => {
                                                        if (location.type === 'sub-zone' && (location.parentLocation._id === property.mainZone || location.parentLocation._id === property.mainZone._id)) {
                                                            return <option key={location._id} value={location._id}>{location.name}</option>
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{ "width": '100%', marginTop: '20px' }} >
                                <label style={{ marginBottom: '0px' }} >Categories</label>
                                {
                                    categories && categories.length !== 0 && <MultiSelect selected={property.categories.map((item) => item._id)} inputOptions={categories} onChange={categoriesChangeHandler} />
                                }
                            </div>
                            <div className="form-group">
                                <label>Overview Description</label>
                                <TextEditor name="overviewDescription" value={property.overviewDescription} onChange={textChangeHandler} />
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-4 mb-10">
                                        <label>Land Area</label>
                                        <input name='landArea' onChange={textChangeHandler} value={property.landArea} type="text" className="form-control shadow-none" placeholder="Enter Land Area" />
                                    </div>
                                    <div className="col-lg-4 mb-10">
                                        <label>Units (BHK)</label>
                                        <input name='units' onChange={textChangeHandler} value={property.units} type="text" className="form-control shadow-none" placeholder="Enter Units (1, 2 BHK)" />
                                    </div>
                                    <div className="col-lg-4 mb-10">
                                        <label>Open Space</label>
                                        <input name='openSpace' onChange={textChangeHandler} value={property.openSpace} type="text" className="form-control shadow-none" placeholder="Enter Open Space" />
                                    </div>
                                    <div className="col-lg-4 mb-10">
                                        <label>No. Of Flats</label>
                                        <input name='noOfFlats' onChange={textChangeHandler} value={property.noOfFlats} type="text" className="form-control shadow-none" placeholder="Enter No. of Flats" />
                                    </div>
                                    <div className="col-lg-4 mb-10">
                                        <label>No. Of Tower</label>
                                        <input name='noOfTowers' onChange={textChangeHandler} value={property.noOfTowers} type="text" className="form-control shadow-none" placeholder="Enter No. of Tower" />
                                    </div>
                                    <div className="col-lg-4 mb-10">
                                        <label>No. Of Floors</label>
                                        <input name='noOfFloors' onChange={textChangeHandler} value={property.noOfFloors} type="text" className="form-control shadow-none" placeholder="Enter No. of Floors" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Floor Plan Title</label>
                                        <input name='floorPlanTitle' onChange={textChangeHandler} value={property.floorPlanTitle} type="text" className="form-control shadow-none" placeholder="Enter Floor Plan Title" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Amenities Title</label>
                                        <input name='amenitiesTitle' onChange={textChangeHandler} value={property.amenitiesTitle} type="text" className="form-control shadow-none" placeholder="Enter Amenities Title" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Location Title</label>
                                        <input name='locationTitle' onChange={textChangeHandler} value={property.locationTitle} type="text" className="form-control shadow-none" placeholder="Enter Location Title" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Location Map</label>
                                        <input name='locationMap' onChange={textChangeHandler} value={property.locationMap} type="text" className="form-control shadow-none" placeholder="Enter IFRAME Address from Google Map" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Gallery Title</label>
                                        <input name='galleryTitle' onChange={textChangeHandler} value={property.galleryTitle} type="text" className="form-control shadow-none" placeholder="Enter Gallery Title" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Gallery Description</label>
                                        <input name='galleryDescription' onChange={textChangeHandler} value={property.galleryDescription} type="text" className="form-control shadow-none" placeholder="Enter Gallery Description" />
                                    </div>
                                </div>
                            </div>
                            <PlacesNearBy propertyId={params.propertyId} onChange={placesNearByHandler} />
                            <div style={{ "width": '100%', marginTop: '20px' }} >
                                <label>Amenities</label>
                                {
                                    amenities && amenities.length !== 0 && <MultiSelect selected={property.amenities.map((item) => item._id)} inputOptions={amenities} onChange={amenityChangeHandler} />
                                }
                            </div>
                            <div className="form-group">
                                <label>Property Video Title</label>
                                <input name='propertyVideoTitle' onChange={textChangeHandler} value={property.propertyVideoTitle} type="text" className="form-control shadow-none" placeholder="Enter Property Video Title" />
                            </div>
                            <div className="form-group">
                                <label>Meta Title</label>
                                <input name='metaTitle' onChange={textChangeHandler} value={property.metaTitle} type="text" className="form-control shadow-none" placeholder="Enter Meta Title" />
                            </div>
                            <div className="form-group">
                                <label>Meta Description</label>
                                <textarea name='metaDescription' onChange={textChangeHandler} value={property.metaDescription} className="form-control shadow-none" placeholder="Enter Meta Description"></textarea>
                            </div>
                            <div className="form-group">
                                <label>Meta Keywords</label>
                                <input name='metaKeyWords' onChange={textChangeHandler} value={property.metaKeyWords} type="text" className="form-control shadow-none" placeholder="Enter Meta Keywords For E.g. Keyword1, Keyword2, Keyword3" />
                            </div>
                            <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                <input name='showOnHomePage' onChange={checkBoxChangeHandler} checked={property.showOnHomePage} type="checkbox" className="custom-control-input" id="showonhomepage" />
                                <label className="custom-control-label" for="showonhomepage">Show on Homepage</label>
                            </div>
                            <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                <input name='propertyStatus' onChange={checkBoxChangeHandler} checked={property.propertyStatus} type="checkbox" className="custom-control-input" id="propertystatus" />
                                <label className="custom-control-label" for="propertystatus">Property Status</label>
                            </div>
                            <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                <input name='onGoingProject' onChange={checkBoxChangeHandler} checked={property.onGoingProject} type="checkbox" className="custom-control-input" id="ongoingproject" />
                                <label className="custom-control-label" for="ongoingproject">Ongoing Project</label>
                            </div>
                            <div>
                                <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input name='floorPlanShow' onChange={checkBoxChangeHandler} checked={property.floorPlanShow} type="checkbox" className="custom-control-input" id="floorplanshow" />
                                    <label className="custom-control-label" for="floorplanshow">Show Floor Plan</label>
                                </div>
                                <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input name='amenitiesShow' onChange={checkBoxChangeHandler} checked={property.amenitiesShow} type="checkbox" className="custom-control-input" id="amenitiesShow" />
                                    <label className="custom-control-label" for="amenitiesShow">Show Amenities</label>
                                </div>
                                <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input name='locationShow' onChange={checkBoxChangeHandler} checked={property.locationShow} type="checkbox" className="custom-control-input" id="locationShow" />
                                    <label className="custom-control-label" for="locationShow">Show Location</label>
                                </div>
                                <div className="custom-control custom-checkbox my-1 mr-sm-2">
                                    <input name='galleryShow' onChange={checkBoxChangeHandler} checked={property.galleryShow} type="checkbox" className="custom-control-input" id="galleryShow" />
                                    <label className="custom-control-label" for="galleryShow">Show Gallery</label>
                                </div>

                            </div>
                            <hr />
                            <div className="form-group">
                                <button className="btn-orange border-none cursor-pointer mr-3">Submit</button>
                                <button onClick={() => navigate('/property-listing')} className="btn-danger border-none cursor-pointer">Cancel</button>
                            </div>
                        </form>
                        <div className="col-lg-4 bg-light padding-40">
                            <h3>Images Section</h3>
                            <hr />
                            <ImagePicker for='property' label='Property Thumbnail' name='thumbnail' onChange={thumbnailChangeHandler} onDelete={thumbnailDeleteHandler} />
                            <ImagePicker for='property' label='Property Offer' name='offer' onChange={offerChangeHandler} onDelete={offerDeleteHandler} />
                            <FilePicker label='Brochure' name='brochure' onChange={brochureChangeHandler} onDelete={brochureDeleteHandler} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
