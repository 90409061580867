/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import DragAndDropFiles from './DragAndDropFiles';
import './GalleryImages.css'

function readFileAsURL(file) {
    return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
            resolve(fr.result)
        }

        fr.onerror = function () {
            reject(fr);
        }

        fr.readAsDataURL(file);
    })
}

const GalleryImages = (props) => {
    const [files, setFiles] = useState([])
    const [previewList, setPreviewList] = useState([]);

    useEffect(() => {

        props.onChange(files);

        const temp = [];

        files.forEach((file) => {
            temp.push(readFileAsURL(file))
        })

        Promise.all(temp).then((value) => {
            setPreviewList(value);
        })

    }, [files])


    const onFilesUpload = (filesList) => {
        setFiles((prevState) => {
            return [...prevState, ...filesList]
        })
    }

    const removeGalleryImageHander = (idx) => {
        let newPreviewList = [...previewList];
        newPreviewList.splice(idx, 1)

        let newFiles = [...files];
        newFiles.splice(idx, 1);

        if (newFiles.length === 0) {
            setPreviewList([])
            setFiles([])
        } else {
            setPreviewList(newPreviewList)
            setFiles(newFiles)
        }
    }

    return (
        <div className='gallery-images' >
            <h1 style={{ fontSize: '1.2rem' }} >Gallery Images</h1>
            <DragAndDropFiles onFilesUpload={onFilesUpload} />
            <div className='preview-container' >
                {
                    previewList.map((item, idx) => {
                        return <div className='gallery-img-container' >
                            <img className='gallery-img' src={item} alt='gallery' />
                            <button
                                type='button'
                                className='gallery-img-remove-btn'
                                onClick={() => removeGalleryImageHander(idx)}
                            >
                                <ion-icon name="trash-bin"></ion-icon>
                            </button>
                        </div>
                    })
                }
            </div>
        </div>
    )

}

export default GalleryImages;