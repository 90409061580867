/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './FormPlacesNearByInput.css';
import axios from 'axios';
import TextEditor from '../TextEditor/TextEditor';


const FormPlacesNearByInput = (props) => {

    const serverURL = process.env.REACT_APP_SERVER_URL;

    const defaultState = {
        range: '',
        places: ''
    }

    const [data, setData] = useState([]);
    const [currItem, setCurrItem] = useState(defaultState)
    const [editMode, setEditMode] = useState(false);

    const rangeChangeHandler = (event) => {
        setCurrItem((prevState) => {
            return {
                ...prevState,
                range: event.target.value
            }
        })
    }

    const placesChangeHandler = (data) => {
        setCurrItem((prevState) => {
            return {
                ...prevState,
                places: data.value
            }
        })
    }

    const addNewHandler = () => {

        if (!currItem || !currItem.range || currItem.length === 0 || !currItem.places || currItem.places === 0) {
            toast.error("This didn't work. 😞");
            return;
        }

        setData((prevState) => {
            const newData = [...prevState];
            newData.push(currItem);
            return newData
        })

        setCurrItem(defaultState)
    }

    const editPlacesHandler = (itemToEdit, idx) => {
        setCurrItem({
            idx,
            ...itemToEdit
        });
        setEditMode(true);
    }

    const deletePlaceHandler = (idx) => {
        const newData = [...data];
        newData.splice(idx, 1);
        setData(newData)
    }

    const saveEditHandler = () => {

        if (!currItem || !currItem.range || currItem.length === 0 || !currItem.places || currItem.places === 0) {
            toast.error("This didn't work. 😞");
            return;
        }

        setData((prevState) => {
            const newData = [...prevState];
            newData[currItem.idx] = {
                range: currItem.range,
                places: currItem.places
            };
            return newData
        })

        setEditMode(false)
        setCurrItem(defaultState)
    }

    useEffect(() => {

        const getSiteData = async (id) => {
            const res = await axios({
                method: 'GET',
                url: `${serverURL}/api/property/${id}`
            })
            setData(res.data.data.placesNearBy);
        }

        if (props.propertyId && props.propertyId.length !== 0) {
            getSiteData(props.propertyId);
        }

    }, [])

    useEffect(() => {
        props.onChange(data);
    }, [data])

    return (
        <Fragment>
            <div className='places-near-by' >
                <div className='heading-div' >
                    <label>Places Near By</label>
                    <div className="places-nearby-form" >
                        <input className='form-control shadow-none' autoComplete='off' id='new-range' label='Range' type='text' value={currItem.range} onChange={rangeChangeHandler} />
                        <TextEditor id='new-places' label='Places' type='editor' value={currItem.places} onChange={placesChangeHandler} />
                        {!editMode && <button type='button' className='btn-orange border-none cursor-pointer mr-3' onClick={addNewHandler} >Add</button>}
                        {editMode && <button type='button' className='btntag-edit' onClick={() => {
                            saveEditHandler()
                        }} >Save Edit</button>}
                    </div>
                </div>
                <div>
                    {
                        data.map((item, idx) => {
                            return (
                                <Accordion key={item.range} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${item.range}-content`}
                                        id={`${item.range}-header`}
                                    >
                                        <Typography variant='h4' >{item.range}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='places-list' dangerouslySetInnerHTML={{ __html: item.places }}></div>
                                        <div className='d-flex' >
                                            <button className='btn-orange border-none cursor-pointer mr-3' type='button' onClick={() => {
                                                editPlacesHandler(item, idx);
                                            }} >Edit</button>
                                            <button className='btn-danger border-none cursor-pointer' type='button' onClick={() => {
                                                deletePlaceHandler(idx);
                                            }} >Delete</button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )

}

export default FormPlacesNearByInput;