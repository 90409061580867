/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function Dashboard() {

  const backendURL = process.env.REACT_APP_SERVER_URL;

  const [properties, setProperties] = useState([]);
  const [inquiries, setInquiries] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [jobPosts, setJobPosts] = useState([]);

  const [onGoingProjects, setOnGoingProjects] = useState(0);
  const [pastProjects, setPastProjects] = useState(0);
  const [inquiriesNumber, setInquiriesNumber] = useState(0);
  const [meetingsNumber, setMeetingsNumber] = useState(0);
  const authSlice = useSelector((state) => state.auth)

  const getProperties = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${backendURL}/api/property`
      })

      if (res && res.status === 200) {
        setProperties(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {

    }
  }

  const getInquiries = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${backendURL}/api/contact-form`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        setInquiries(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {

    }
  }

  const getMeetings = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${backendURL}/api/schedule-meeting`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        setMeetings(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {

    }
  }

  const getJobPosts = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${backendURL}/api/job-post`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        }
      })

      if (res && res.status === 200) {
        setJobPosts(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getProperties();
    getInquiries();
    getMeetings();
    getJobPosts();
  }, []);

  useEffect(() => {

    let ongoing = 0;
    let past = 0;

    properties.forEach((item) => {
      if (item.onGoingProject === true) {
        ongoing += 1;
      } else {
        past += 1;
      }
    })

    setOnGoingProjects(ongoing);
    setPastProjects(past);

  }, [properties])

  useEffect(() => {

    let inqueriesNum = 0;

    inquiries.forEach((item) => {
      if (item.createdAt) {
        const entryDate = new Date(item.createdAt);
        const todaysDate = new Date();
        if (entryDate.getFullYear() === todaysDate.getFullYear() && entryDate.getDate() === todaysDate.getDate() && entryDate.getMonth() === todaysDate.getMonth()) {
          inqueriesNum += 1;
        }
      }
    })
    setInquiriesNumber(inqueriesNum);
  }, [inquiries])

  useEffect(() => {
    setMeetingsNumber(meetings.length);
  }, [meetings])

  return (
    <Fragment>
      <div className="data-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="card bg-dark text-white">
                <div className="card-img-overlay">
                  <i className="fa fa-building-o"></i>
                  <h5>Ongoing Projects</h5>
                  <h1>{onGoingProjects}</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-dark text-white">
                <div className="card-img-overlay">
                  <i className="fa fa-home"></i>
                  <h5>Past Projects</h5>
                  <h1>{pastProjects}</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-dark text-white">
                <div className="card-img-overlay">
                  <i className="fa fa-paper-plane-o"></i>
                  <h5>Todays Inquiries</h5>
                  <h1>{inquiriesNumber}</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card bg-dark text-white">
                <div className="card-img-overlay">
                  <i className="fa fa-user-o"></i>
                  <h5>Scheduled Meetings</h5>
                  <h1>{meetingsNumber}</h1>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-8">
              <h3>Latest Inquiries</h3>
              <hr />
              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th className="text-center">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    inquiries && inquiries.length !== 0 && inquiries.map((item, idx) => {
                      if (idx < 5) {
                        return (
                          <tr key={inquiries[inquiries.length - idx - 1]._id}>
                            <td>{idx + 1}</td>
                            <td>{inquiries[inquiries.length - idx - 1].name}</td>
                            <td>{inquiries[inquiries.length - idx - 1].email}</td>
                            <td>{inquiries[inquiries.length - idx - 1].phone}</td>
                            <td className="text-center">{inquiries[inquiries.length - idx - 1].createdAt.split('T')[0]}</td>
                            {/*<td className="text-center"><a href="/#" className="btntag-danger">Delete</a></td>*/}
                          </tr>
                        )
                      } else {
                        return null;
                      }
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="col-lg-4">
              <h3>Latest Job Posts</h3>
              <hr />
              <div className=" bg-light padding-40">
                {
                  jobPosts && jobPosts.map((item, idx) => {
                    if (idx < 5) {
                      return (
                        <div key={jobPosts[jobPosts.length - idx - 1]._id} className="form-group job-post-box">
                          <div className="job-icon">
                            <i className="fa fa-quote-left"></i>
                          </div>
                          <div className="job-text">
                            <h5>{jobPosts[jobPosts.length - idx - 1].role}</h5>
                            <p>{jobPosts[jobPosts.length - idx - 1].location}</p>
                            <p>{jobPosts[jobPosts.length - idx - 1].experience}</p>
                          </div>
                        </div>
                      )
                    } else {
                      return null;
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
