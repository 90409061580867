import React, { Fragment } from 'react'

export default function Footer() {
  return (
    <Fragment>
      <footer className="footer-static">
        <p className="clearfix mb-0">
          &copy; 2023 KOHO
        </p>
      </footer>
    </Fragment>
  )
}
