/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import GalleryImages from '../../../Components/GalleryImages/GalleryImages';
import GalleryDisplay from '../../../Components/GalleryDisplay/GalleryDisplay'

export default function FloorPlan() {

    const serverURL = process.env.REACT_APP_SERVER_URL;

    const defaultAddState = {
        property: '',
        image: null
    }

    const [properties, setProperties] = useState([]);
    const [addState, setAddState] = useState(defaultAddState)
    const [galleryImagesToUpload, setGalleryImagesToUpload] = useState(null)
    const authSlice = useSelector((state) => state.auth);

    const [currProperty, setCurrProperty] = useState(null);

    const [galleryImages, setGalleryImages] = useState([]);

    const getProperties = async () => {

        try {
            const res = await axios({
                method: 'GET',
                url: `${serverURL}/api/property`,
            })

            try {
                const { data } = res.data;
                setProperties(data);
            } catch (error) {
                throw new Error();
            }
        } catch (error) {
            toast.error("Couldn't fetch the data")
        }
    }

    const addPropertyChangeHandler = (event) => {
        setAddState((prevState) => {
            return {
                ...prevState,
                property: event.target.value
            }
        })
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        if (galleryImagesToUpload && galleryImagesToUpload.length !== 0) {

            const postData = async (formData) => {
                await axios({
                    method: 'POST',
                    url: `${serverURL}/api/gallery-image/`,
                    headers: {
                        "Content-Type": 'multipart/form-data',
                        "Authorization": `Bearer ${authSlice.token}`
                    },
                    data: formData
                })
            }

            galleryImagesToUpload.forEach((item) => {
                const data = new FormData();

                data.append('property', addState.property);
                data.append('image', item);

                postData(data);
            })
        }

        getImages();
        setAddState(defaultAddState);
    }

    const getImages = async () => {

        try {
            const resGalleryImages = await toast.promise(
                axios({
                    method: 'GET',
                    url: `${serverURL}/api/gallery-image/${currProperty}`,
                }), {
                loading: 'Fetching...',
                success: 'Data fetched successfully',
                error: "Couldn't fetch the data"
            }
            )
            if (resGalleryImages && resGalleryImages.status === 200) {
                const imgs = []
                resGalleryImages.data.data.forEach((item) => {
                    imgs.push({
                        id: item._id,
                        property: item.property,
                        path: `${serverURL}/${item.path}`
                    })
                })
                setGalleryImages(imgs);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getProperties();
    }, [])

    useEffect(() => {
        if (currProperty === '') {
        } else {
            getImages();
        }
    }, [currProperty])


    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Gallery</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <label>Select property to view data</label>
                                <select onChange={(event) => setCurrProperty(event.target.value)} value={currProperty} className="form-control shadow-none mb-30">
                                    <option value='' >Property ?</option>
                                    {
                                        properties.map((property) => {
                                            return (
                                                <option key={property._id} value={property._id}>{property.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div>
                                    {
                                        currProperty !== '' && galleryImages && galleryImages.length !== 0 && <div  >
                                            {
                                                galleryImages && galleryImages.length !== 0 && <div>
                                                    <h3 className='img-heading text-align-center mb-10' >Gallery Image</h3>
                                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem' }} >
                                                        {
                                                            galleryImages.map((item) => {
                                                                return <GalleryDisplay src={item.path} propertyID={item.property} id={item.id} refresh={getImages} />
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <form className="col-lg-4 bg-light padding-40" onSubmit={onSubmitHandler}>
                            <h3>Add Gallery Images</h3>
                            <hr />
                            <div className="form-group">
                                <label>Property Name</label>
                                <select className="form-control shadow-none" value={addState.property} onChange={addPropertyChangeHandler} >
                                    <option value="">Property ?</option>
                                    {
                                        properties.map((property) => {
                                            return (
                                                <option key={property._id} value={property._id}>{property.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                {addState.property && <GalleryImages onChange={setGalleryImagesToUpload} />}
                            </div>
                            <div className="form-group">
                                <button type='submit' className="btn-orange-sm border-none cursor-pointer">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
