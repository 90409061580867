import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faqActions } from '../../store/faqSlice';

import './Modal.css';

const Modal = () => {

    const faq = useSelector((state) => state.faq);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(faqActions.closeModal())
    }

    return (
        <Fragment>
            <div className='custom-modal'>
                <button onClick={closeModal} className=' btntag-danger' style={{ marginRight: '0px' }} >Close</button>
                <div className="content">
                    <p><strong>{faq.question}</strong></p>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} ></div>
                </div>
            </div>
        </Fragment>
    )
}

export default Modal;