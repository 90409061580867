import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const NotFound = () => {

    const authSlice = useSelector((state) => state.auth)
    const navigate = useNavigate();

    const [show, setShow] = useState(false);


    if (authSlice.isLoggedIn === false) {
        navigate('/login')
    }

    useState(() => {
        const showTimeout = setTimeout(() => {
            setShow(true);
        }, 5000)

        return () => {
            clearTimeout(showTimeout)
        }

    }, [])

    return (
        show && (
            <div className='not-found' >
                <div className="mainbox">
                    <div style={{ marginBottom: '70px' }} className="err">404</div>
                    <div className="msg">O-o-oh! Something broke.</div>
                    {
                        authSlice.isLoggedIn === true ? (
                            <Link to='/' className='btn-orange'>GO BACK</Link>
                        ) : (
                            <Link to='/login' className='btn-orange'>GO BACK</Link>
                        )
                    }
                </div>
            </div>
        )
    )
}

export default NotFound