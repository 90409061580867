import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios'
import { toast } from 'react-hot-toast'

import ImagePicker from '../../../Components/ImagePicker/ImagePicker'
import { useSelector } from 'react-redux';

export default function Amenities() {

  const [amenities, setAmenities] = useState([]);
  const [name, setName] = useState('');
  const [logo, setLogo] = useState(null);
  const authSlice = useSelector((state) => state.auth);

  const getAllAmenities = async () => {

    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_URL}/api/amenities`
      })

      if (res && res.status === 200) {
        toast.success("Data fetched successfully!");
        setAmenities(res.data.data)
      }
    } catch (error) {
      toast.error("Couldn't fetch the data.")
    }

  }

  const nameChangeHandler = (event) => {
    setName(event.target.value);
  }

  const logoChangeHandler = (file) => {
    setLogo(file);
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    const data = new FormData();

    data.append('name', name);
    data.append('logo', logo);

    try {

      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/amenities`,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${authSlice.token}`
        },
        data
      })
      if (res && res.status) {
        toast.success('Data added successfully!');
        const newAmenities = [...amenities];
        newAmenities.push({
          _id: res.data.data._id,
          name: res.data.data.name,
          logo: res.data.data.logo
        })
        setAmenities(newAmenities);
        setName('');
        setLogo(null);
      }

    } catch (error) {
      toast.error("Couldn't create the data.")
      setName('');
      setName(null)
    }

  }

  const deleteHandler = async (id) => {

    if (!id) {
      toast.error('No ID to delete')
    }

    try {

      const res = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_SERVER_URL}/api/amenities/${id}`,
        headers: {
          "Authorization": `Bearer ${authSlice.token}`
        },
      })

      if (res && res.status === 200) {
        toast.success('Data deleted successfully!');
        const newAmenities = amenities.filter((item) => item._id !== id);
        setAmenities(newAmenities)
      }

    } catch (error) {
      toast.error("Couldn't delete the data.")
    }

  }

  useEffect(() => {
    getAllAmenities();
  }, [])

  return (
    <Fragment>
      <div className="data-box mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-between">
                <h2>Amenities</h2>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>SR No.</th>
                    <th>Amenities Name</th>
                    <th className="text-center">Image</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                {amenities && amenities.length !== 0 && (
                  <tbody>
                    {
                      amenities.map((amenity, idx) => {
                        return (
                          <tr key={amenity._id} >
                            <td>{idx + 1}</td>
                            <td>{amenity.name}</td>
                            <td className="text-center">
                              <img src={`${process.env.REACT_APP_SERVER_URL}/${amenity.logo}`} alt="" className="amenities-img" />
                            </td>
                            <td className="text-center">
                              <button type='button' onClick={() => deleteHandler(amenity._id)} className="btntag-danger">
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                )}
              </table>
            </div>
            <form onSubmit={submitHandler} className="col-lg-4 bg-light padding-40">
              <h3>Add Amenities</h3>
              <hr />
              <div className="form-group">
                <label>Amenity Name</label>
                <input value={name} onChange={nameChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Amenity Name" />
              </div>
              <div className="form-group">
                {name && name.length !== 0 && <ImagePicker label='Amenity Logo' name='logo' onChange={logoChangeHandler} />}
              </div>
              <div className="form-group">
                <button className="btn-orange-sm border-none cursor-pointer">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
