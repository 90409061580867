/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import ImagePicker from '../../Components/ImagePicker/ImagePicker';

export default function Team() {

    const backendURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth);
    const defaultState = {
        name: '',
        description: '',
        facebook: '',
        instagram: '',
        twitter: '',
        image: null
    }

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState(defaultState);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState({
        removeImage: false
    });

    const getData = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${backendURL}/api/team`
            })

            if (res && res.status === 200) {
                toast.success("Data fetched successfully!")
                setData(res.data.data)
            }

        } catch (error) {
            toast.success("Couldn't fetch the data!")
        }

    }

    const textChangeHandler = (event) => {
        if (editMode === true) {
            setEditData((prevState) => {
                return {
                    ...prevState,
                    [event.target.name]: event.target.value
                }
            })
        }

        setFormData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const fileChangeHandler = (file) => {
        if (file) {
            if (editMode === true) {
                setEditData((prevState) => {
                    return {
                        ...prevState,
                        image: file
                    }
                })
            } else {
                setFormData((prevState) => {
                    return {
                        ...prevState,
                        image: file
                    }
                })
            }
        }
    }

    const fileRemoveHandler = () => {
        if (editMode === true) {
            setEditData((prevState) => {
                return {
                    ...prevState,
                    image: undefined
                }
            })
        } else {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    image: undefined
                }
            })
        }
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        if (formData.name.length === 0) {
            return toast.error("Kindly enter a name!");
        }

        if (formData.description.length === 0) {
            return toast.error("Kindly enter description");
        }

        if (!formData.image) {
            return toast.error("Kindly select an image");
        }

        const requestData = new FormData();

        for (let key in formData) {
            requestData.append(key, formData[key]);
        }

        try {

            const res = await axios({
                method: "POST",
                url: `${backendURL}/api/team`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: requestData
            })

            if (res && res.status === 200) {
                toast.success("Data created successfully!");
                const newData = [...data];
                newData.push(res.data.data);
                setData(newData);
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't create the data!")
        }

        setFormData(defaultState);
    }

    const editHandler = (obj, idx) => {
        setFormData({
            ...obj,
            idx
        })
        setEditMode(true);
        setEditData({
            removeImage: false
        });
    }

    const removeExistingImageHandler = () => {
        setEditData((prevState) => {
            return {
                ...prevState,
                image: undefined,
                removeImage: true
            }
        })
        setFormData((prevState) => {
            return {
                ...prevState,
                image: undefined
            }
        })
    }

    const saveEditHandler = async (id) => {

        const editRequestData = new FormData();

        for (let key in editData) {
            if (editData[key] !== undefined && editData[key] !== null) {
                editRequestData.append(key, editData[key]);
            }
        }

        try {

            const res = await axios({
                method: "PATCH",
                url: `${backendURL}/api/team/${id}`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: editRequestData
            })

            if (res && res.status === 200) {
                toast.success("Data edited successfully!");
                const newData = [...data];
                newData[formData.idx] = res.data.data
                setData(newData);
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't edit the data!")
        }

        setFormData(defaultState);
        setEditMode(false);
        setEditData({});

    }

    const deleteHandler = async (id) => {

        if (!id || id.length === 0) {
            return toast.error("No ID")
        }

        try {

            const res = await axios({
                method: "DELETE",
                url: `${backendURL}/api/team/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                }
            })

            if (res && res.status === 200) {
                toast.success("Data deleted successfully!");
                const newData = data.filter((item) => item._id !== id);
                setData(newData);
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't delete the data!")

        }

    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Team Members</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Member Image</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length !== 0 && data.map((item, idx) => {
                                            return (
                                                <tr key={item._id} >
                                                    <td>{idx + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.description}</td>
                                                    <td><img src={`${backendURL}/${item.image}`} alt="" className="img-fluid" width="70px" /></td>
                                                    <td className="text-center">
                                                        <button onClick={() => editHandler(item, idx)} type='button' className="btntag-edit">Edit</button>
                                                        <button onClick={() => deleteHandler(item._id)} className="btntag-danger">Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={submitHandler} className="col-lg-4 bg-light padding-40">
                            <h3>{editMode === true ? "Edit" : "Add"} Team Member</h3>
                            <hr />
                            {
                                editMode === true && formData.image && formData.image.length !== 0 && (
                                    <div style={{ display: 'grid', gridTemplateColumns: '4fr 6fr', alignItems: 'center', gap: '1rem', padding: '.5rem 0rem', }} >
                                        <img style={{ width: "100%" }} src={`${backendURL}/${formData.image}`} alt='profile' />
                                        <div>
                                            <button type='button' style={{ justifySelf: 'center', textAlign: 'center', marginBottom: '10px' }} className='btntag-danger' onClick={removeExistingImageHandler} >Remove</button>
                                            <p>If you need to change the image, kindly upload new image below</p>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="form-group">
                                <label>Name</label>
                                <input name='name' value={formData.name} onChange={textChangeHandler} type="tel" className="form-control shadow-none" placeholder="Enter Name" />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea name='description' value={formData.description} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Description" />
                            </div>
                            <div className="form-group">
                                <label>Facebook Profile URL - <Link to={formData.facebook} style={{ color: "#f1622c" }}><i className="fa fa-link"></i></Link></label>
                                <input name='facebook' value={formData.facebook} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Facebook Profile URL" />
                            </div>
                            <div className="form-group">
                                <label>Instagram Profile URL - <Link to={formData.instagram} style={{ color: "#f1622c" }}><i className="fa fa-link"></i></Link></label>
                                <input name='instagram' value={formData.instagram} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Instagram Profile URL" />
                            </div>
                            <div className="form-group">
                                <label>Twitter Profile URL - <Link to={formData.twitter} style={{ color: "#f1622c" }}><i className="fa fa-link"></i></Link></label>
                                <input name='twitter' value={formData.twitter} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Twitter Profile URL" />
                            </div>
                            <div className="form-group">
                                {formData.name && formData.description && <ImagePicker onDelete={fileRemoveHandler} onChange={fileChangeHandler} name='image' title='Profile Image' />}
                            </div>
                            <div className="form-group">
                                {
                                    editMode === true ? (
                                        <button type='button' onClick={() => saveEditHandler(formData._id)} className="btn-orange-sm border-none cursor-pointer">Edit</button>

                                    ) : (
                                        <button type='submit' className="btn-orange-sm border-none cursor-pointer">Submit</button>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
