/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import ImagePicker from '../../Components/ImagePicker/ImagePicker';

export default function GrowthTimeline() {

    const backendURL = process.env.REACT_APP_SERVER_URL;
    const authSlice = useSelector((state) => state.auth)
    const defaultState = {
        year: '',
        description: '',
        image: null
    }

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState(defaultState);

    const getData = async () => {

        try {
            const res = await axios({
                method: "GET",
                url: `${backendURL}/api/growth-timeline`
            })

            if (res && res.status === 200) {
                toast.success("Data fetched successfully!")
                setData(res.data.data)
            }

        } catch (error) {
            toast.success("Couldn't fetch the data!")
        }

    }

    const textChangeHandler = (event) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const fileChangeHandler = (file) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                image: file
            }
        })
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        if (formData.year.length === 0) {
            return toast.error("Kindly enter an year!");
        }

        if (formData.description.length === 0) {
            return toast.error("Kindly enter description");
        }

        if (!formData.image) {
            return toast.error("Kindly select an image");
        }

        const requestData = new FormData();

        for (let key in formData) {
            requestData.append(key, formData[key]);
        }

        try {

            const res = await axios({
                method: "POST",
                url: `${backendURL}/api/growth-timeline`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${authSlice.token}`
                },
                data: requestData
            })

            if (res && res.status === 200) {
                toast.success("Data created successfully!");
                const newData = [...data];
                newData.push(res.data.data);
                setData(newData);
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't create the data!")
        }

        setFormData(defaultState);
    }

    const deleteHandler = async (id) => {

        if (!id || id.length === 0) {
            return toast.error("No ID")
        }

        try {

            const res = await axios({
                method: "DELETE",
                url: `${backendURL}/api/growth-timeline/${id}`,
                headers: {
                    "Authorization": `Bearer ${authSlice.token}`
                },
            })

            if (res && res.status === 200) {
                toast.success("Data deleted successfully!");
                const newData = data.filter((item) => item._id !== id);
                setData(newData);
            } else {
                throw new Error();
            }

        } catch (error) {
            toast.error("Couldn't delete the data!")

        }

    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <Fragment>
            <div className="data-box mb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>Growth Timeline</h2>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">

                            <table className="table table-bordered table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>SR No.</th>
                                        <th>Year</th>
                                        <th>Description</th>
                                        <th>Timeline Image</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                {
                                    data && data.length !== 0 && (
                                        <tbody>
                                            {
                                                data.map((item, idx) => {
                                                    return (
                                                        <tr key={item._id} >
                                                            <td>{idx + 1}</td>
                                                            <td>{item.year}</td>
                                                            <td>{item.description}</td>
                                                            <td>
                                                                <img src={`${backendURL}/${item.image}`} alt="" class="img-fluid" width="70px" />
                                                            </td>
                                                            <td className="text-center">
                                                                <button onClick={() => deleteHandler(item._id)} className="btntag-danger">Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                        <form onSubmit={submitHandler} className="col-lg-4 bg-light padding-40">
                            <h3>Add Timeline</h3>
                            <hr />
                            <div className="form-group">
                                <label>Year</label>
                                <input name='year' value={formData.year} onChange={textChangeHandler} type='text' className="form-control shadow-none" placeholder="Enter Year" />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input name='description' value={formData.description} onChange={textChangeHandler} type="text" className="form-control shadow-none" placeholder="Enter Timeline Description" />
                            </div>
                            <div className="form-group">
                                <div className="image-container mt-10">
                                    {formData.year && <ImagePicker onChange={fileChangeHandler} name='image' title='Growth Timeline Image' />}
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn-orange-sm border-none cursor-pointer">Submit</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
